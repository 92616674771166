import React, { useEffect, useRef } from "react";
import { FaCommentAlt } from "react-icons/fa";
import { ChatMessage } from "./ChatMessage";
import { UserDetails } from "./UserDetails";
import { MessageInput } from "./MessageInput";
import { ChatContainerProps, UserDetailsProps } from "../types";
import {
  extractNameAndEmail,
  getInitials,
  omitStart,
  getSenderType,
} from "../utils/messageHelpers";
// import { CallWidget } from './call_widget';
import { formatDate } from "../utils/formatters";

export const ChatContainer: React.FC<ChatContainerProps> = ({
  selectedLog,
  chatMessages,
  intervenedChats,
  lastHumanAssistanceRequest,
  onSendMessage,
  onIntervene,
  onReleaseChat,
  assignedChats,
  currentUserId,
}: Readonly<ChatContainerProps>) => {
  const [message, setMessage] = React.useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  let currentAgent: string | null = null;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  // Get user details safely with null checks
  const userDetails = React.useMemo(() => {
    if (!selectedLog?.message) {
      return { name: "", initials: "" };
    }
    const { name } = extractNameAndEmail(selectedLog.message);
    return {
      name,
      initials: getInitials(name),
    };
  }, [selectedLog]);

  // Prepare user details props safely
  const userDetailsProps = React.useMemo(() => {
    if (!selectedLog) {
      return {
        log: null,
        isHighlighted: false,
        isIntervened: false,
        onIntervene: () => {},
        onRelease: () => {},
        isAssignedToOtherAgent: false,
        assignedAgentName: "",
        requiresHumanAssistance: false,
      };
    }

    const assignedAgent = assignedChats.get(selectedLog.session_id);
    const isAssignedToOtherAgent =
      assignedAgent && assignedAgent.supportId !== currentUserId;

    return {
      log: selectedLog,
      isHighlighted:
        lastHumanAssistanceRequest?.sessionID === selectedLog.session_id,
      isIntervened: intervenedChats.has(selectedLog.session_id),
      onIntervene: () => onIntervene(selectedLog.session_id),
      onRelease: () => onReleaseChat(selectedLog.session_id),
      isAssignedToOtherAgent,
      assignedAgentName: isAssignedToOtherAgent ? assignedAgent.agentName : "",
      requiresHumanAssistance:
        lastHumanAssistanceRequest?.sessionID === selectedLog.session_id,
    };
  }, [
    selectedLog,
    lastHumanAssistanceRequest,
    intervenedChats,
    onIntervene,
    onReleaseChat,
    assignedChats,
    currentUserId,
  ]);

  const assignedAgent = selectedLog
    ? assignedChats.get(selectedLog.session_id)
    : null;
  const isAssignedToCurrentUser = assignedAgent?.supportId === currentUserId;
  const canInteract = !assignedAgent || isAssignedToCurrentUser;

  return (
    <div className="flex flex-col h-full">
      <div className="flex-shrink-0 p-4 border-b border-gray-300 dark:border-gray-600">
        <div className="p-4 flex items-center space-x-4">
          <div className="w-12 h-12 rounded-full flex items-center justify-center bg-white shadow-lg">
            <div className="w-11 h-11 rounded-full bg-gray-200 flex items-center justify-center text-xl text-gray-700">
              {userDetails.initials}
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <span className="text-sm dark:text-gray-100">
              {userDetails.name || "No user selected"}
            </span>
            <span className="text-xs text-gray-400">
              {selectedLog ? formatDate(selectedLog.created_at) : ""}
            </span>
          </div>
        </div>
      </div>

      {assignedAgent && !isAssignedToCurrentUser && (
        <div className="bg-yellow-100 dark:bg-yellow-800 p-4 text-center">
          <span className="text-yellow-800 dark:text-yellow-200">
            Chat in progress with agent {assignedAgent.agentName}
          </span>
        </div>
      )}

      <div className="grid grid-cols-[65%_35%] flex-grow min-h-0">
        <div className="flex flex-col h-full relative">
          <div className="absolute inset-0 flex flex-col overflow-hidden">
            <div className="flex-grow overflow-y-auto p-4">
              {selectedLog ? (
                <>
                  <div className="text-center text-gray-500 text-xs mb-4">
                    Chat started on {formatDate(selectedLog.created_at)}
                  </div>
                  <div className="space-y-4 mb-4">
                    {chatMessages.map((messagePart, index) => {
                      const sender = getSenderType(messagePart);
                      
                      // Track agent sessions by looking for system messages
                      if (sender === "system") {
                        const message = omitStart(messagePart).trim().toLowerCase();
                        if (message.includes("joined the chat")) {
                          currentAgent = message.split(" joined")[0];
                        } else if (message.includes("left the chat")) {
                          currentAgent = null;
                        }
                      }
                      
                      // Use currentAgent for support messages if available
                      const messageAgentName = 
                        (sender === "human_assistant" || sender === "assistant") && currentAgent
                          ? currentAgent
                          : assignedChats.get(selectedLog.session_id)?.agentName;
                      
                      return (
                        <ChatMessage
                          key={`${selectedLog.id}-${index}`}
                          message={omitStart(messagePart).trim()}
                          sender={sender}
                          initials={
                            sender === "user" 
                              ? userDetails.initials 
                              : messageAgentName 
                                ? getInitials(messageAgentName) 
                                : "AI"
                          }
                          agentName={messageAgentName}
                        />
                      );
                    })}
                  </div>
                </>
              ) : (
                <div className="flex flex-col items-center justify-center h-full">
                  <FaCommentAlt className="text-gray-500 dark:text-gray-400 text-6xl mb-4" />
                  <p className="text-gray-500 dark:text-gray-400">
                    Please Select a Conversation.
                  </p>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>

            {selectedLog &&
              canInteract &&
              intervenedChats.has(selectedLog.session_id) && (
                <div className="flex-shrink-0 p-4 border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
                  <MessageInput
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onSend={() => {
                      if (message.trim()) {
                        onSendMessage(message);
                        setMessage("");
                      }
                    }}
                    disabled={
                      !selectedLog?.session_id ||
                      !intervenedChats.has(selectedLog.session_id)
                    }
                  />
                </div>
              )}
          </div>
        </div>

        <div className="border-l border-gray-300 dark:border-gray-600 overflow-y-auto">
          <div className="p-4">
            <UserDetails {...(userDetailsProps as UserDetailsProps)} />
          </div>
        </div>
      </div>
    </div>
  );
};
