export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleString("en-US", options);
};

export function formatTime(date: Date): string {
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  // Helper to check if dates are the same day
  const isSameDay = (d1: Date, d2: Date) => {
    return d1.getDate() === d2.getDate() &&
           d1.getMonth() === d2.getMonth() &&
           d1.getFullYear() === d2.getFullYear();
  };

  // Format time
  const timeString = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });

  // Check if date is today
  if (isSameDay(date, now)) {
    return `Today, ${timeString}`;
  }

  // Check if date is yesterday
  if (isSameDay(date, yesterday)) {
    return `Yesterday, ${timeString}`;
  }

  // For older dates, show the full date
  const dateString = date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric'
  });
  
  return `${dateString}, ${timeString}`;
} 