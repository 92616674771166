interface MessagePart {
  sender: string;
  content: string;
}

const isMessageStart = (line: string): boolean =>
  line.startsWith("User:") ||
  line.startsWith("Assistant:") ||
  line.startsWith("Support:") ||
  line.startsWith("System:");

const extractSenderAndContent = (line: string): MessagePart => {
  const sender = line.split(":")[0];
  const content = line.substring(line.indexOf(":") + 1).trim();
  return { sender, content };
};

const findNextMessageIndex = (lines: string[], currentIndex: number): number => {
  const nextIndex = lines.findIndex((line, index) => 
    index > currentIndex && isMessageStart(line.trim())
  );
  return nextIndex === -1 ? lines.length : nextIndex;
};

const getSubsequentContent = (lines: string[], startIndex: number, endIndex: number): string => {
  const subsequentLines = lines
    .slice(startIndex + 1, endIndex)
    .map(line => line.trim())
    .filter(line => line !== "");
  
  return subsequentLines.length > 0 ? "\n" + subsequentLines.join("\n") : "";
};
export const splitMessages = (message: string): string[] => {
  const parts: string[] = [];
  const lines = message.split("\n").map(line => line.trim());
  let lastAgentAction: { agent: string; action: 'joined' | 'left' } | null = null;
  let hasMessagesSinceLastAction = false;

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (line === "") continue;

    if (isMessageStart(line)) {
      const { sender, content } = extractSenderAndContent(line);

      if (sender === "System") {
        const joinMatch = content.match(/Support agent (.*?) has joined the chat/);
        const leaveMatch = content.match(/Support agent (.*?) has left the chat/);
        
        if (joinMatch || leaveMatch) {
          const agentName = (joinMatch?.[1] || leaveMatch?.[1]);
          // Skip if we couldn't extract the agent name
          if (!agentName) continue;
          
          const action = joinMatch ? 'joined' : 'left';
          
          // Always show the first action
          if (!lastAgentAction) {
            parts.push(`System: ${agentName} ${action} the chat`);
            lastAgentAction = { agent: agentName, action };
            hasMessagesSinceLastAction = false;
            continue;
          }

          // If there have been messages since the last action, or if it's a different action
          // than the last one for the same agent, show it
          if (hasMessagesSinceLastAction || 
              lastAgentAction.agent !== agentName || 
              lastAgentAction.action !== action) {
            parts.push(`System: ${agentName} ${action} the chat`);
            lastAgentAction = { agent: agentName, action };
            hasMessagesSinceLastAction = false;
          }
        } else {
          parts.push(`${sender}: ${content}`);
          hasMessagesSinceLastAction = true;
        }
      } else {
        const nextIndex = findNextMessageIndex(lines, i);
        const additionalContent = getSubsequentContent(lines, i, nextIndex);
        parts.push(`${sender}: ${content}${additionalContent}`);
        i = nextIndex - 1;
        hasMessagesSinceLastAction = true;
      }
    }
  }

  return parts;
};

export const getLastMessage = (message: string): string => {
  const parts = message.split("\n");
  for (let i = parts.length - 1; i >= 0; i--) {
    if (parts[i].startsWith("User:")) {
      return parts[i].replace(/User:|<p>|<\/p>/g, "").trim();
    } else if (parts[i].startsWith("Assistant:")) {
      return (
        "Assistant: " + parts[i].replace(/Assistant:|<p>|<\/p>/g, "").trim()
      );
    }
  }
  return "";
};

export const extractNameAndEmail = (
  message: string
): { name: string; email: string } => {
  const userMessage = message
    .split("\n")
    .find((part) => part.startsWith("User:"));
  if (!userMessage) return { name: "Unknown", email: "Unknown" };

  const nameMatch = RegExp(/name is ([\w\s'.-]+) and/i).exec(userMessage);
  const emailMatch = RegExp(
    /email is (([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})|([A-Z0-9._%+-]+@[A-Z0-9.-]+))/i
  ).exec(userMessage);

  const name = nameMatch ? nameMatch[1].trim() : "Unknown";
  const email = emailMatch ? emailMatch[1].trim() : "Unknown";

  return { name, email };
};

export const getInitials = (name: string): string => {
  if (!name) return "";
  const nameParts = name.trim().split(" ");
  if (nameParts.length === 1) {
    return nameParts[0].charAt(0).toUpperCase();
  }
  return (
    nameParts[0].charAt(0).toUpperCase() +
    nameParts[nameParts.length - 1].charAt(0).toUpperCase()
  );
};

export const getSenderType = (
  messagePart: string
): "user" | "assistant" | "system" | "human_assistant" => {
  if (messagePart.startsWith("User:")) return "user";
  if (messagePart.startsWith("Support:")) return "human_assistant";
  if (messagePart.startsWith("System:")) return "system";
  return "assistant";
};

export const omitStart = (message: string): string => {
  return message
    .replace(/^(User|Assistant|Support|System):\s*/, "")
    .replace(/<p>|<\/p>/g, "")
    .trim();
}; 