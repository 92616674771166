import { Sidebar } from "flowbite-react";
import type { FC, MouseEvent } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  HiChartPie,
  HiGlobe,
  HiChatAlt2,
  HiLogout,
  HiOutlineQuestionMarkCircle,
} from "react-icons/hi";
import { useSidebarContext } from "../context/useSideBarContext";
import classNames from "classnames";
import "./styles.css";
import { logoutUser } from "../../api/authentication";
import { useAuth } from "../../utils/helpers/authWrapper";
import { useQueryClient } from "@tanstack/react-query";
import { usePermissions, Permission } from '../../utils/hooks/usePermissions';

const MainSidebar: FC = () => {
  const [currentPage, setCurrentPage] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const { isOpenOnSmallScreens } = useSidebarContext();
  const { expired, user, loading } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { can } = usePermissions();
  const theme = {
    root: {
      base: "h-full",
      collapsed: {
        on: "w-16",
        off: "w-64",
      },
      inner:
        "h-full overflow-y-auto overflow-x-hidden rounded bg-gray-800 px-3 py-4 ",
    },
    item: {
      base: "flex items-center justify-center rounded-lg p-2 text-base font-normal text-white hover:bg-primary hover:text-bg dark:text-white dark:hover:bg-gray-700",
    },
  };

  useEffect(() => {
    const newPage = window.location.pathname;
    setCurrentPage(newPage);
  }, []);

  const handleLogout = async (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    try {
      await logoutUser();
      await queryClient.invalidateQueries({ queryKey: ["auth-session"] });
      queryClient.removeQueries({ queryKey: ["auth-session"] });
      queryClient.clear();
      navigate("/authentication");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const getInitials = (name: string): string => {
    const nameParts = name.split(" ");
    const initials = nameParts
      .map((part) => part.charAt(0).toUpperCase())
      .join("");
    return initials;
  };

  const renderSidebarItem = (href: string, icon: FC, label: string) => {
    const isDisabled =
      expired && !["/plans", "/profile", "/logout"].includes(href);

    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (isDisabled) {
        navigate("/plans");
      } else {
        navigate(href);
      }
    };

    return (
      <Sidebar.Item
        as="div"
        icon={icon}
        className={classNames({
          "bg-primary dark:bg-gray-700 text-secondary": href === currentPage,
          "cursor-not-allowed opacity-50": isDisabled,
          "cursor-pointer": !isDisabled,
        })}
        onClick={handleClick}
      >
        <span
          className={classNames("ml-2", {
            hidden: !isHovered && !isOpenOnSmallScreens,
          })}
        >
          {label}
        </span>
      </Sidebar.Item>
    );
  };

  const renderSidebarItems = () => {
    const items: Array<{
      path: string;
      icon: FC;
      label: string;
      permission: Permission | null;
    }> = [
      {
        path: "/dashboard",
        icon: HiChartPie,
        label: "Dashboard",
        permission: "view_dashboard" as Permission
      },
      {
        path: "/domains",
        icon: HiGlobe,
        label: "Domains",
        permission: "manage_domains" as Permission
      },
      {
        path: "/chats",
        icon: HiChatAlt2,
        label: "Chats",
        permission: "view_chats" as Permission
      },
      {
        path: "/documentation",
        icon: HiOutlineQuestionMarkCircle,
        label: "Help",
        permission: null
      }
    ];

    return (
      <Sidebar.Items>
        <Sidebar.ItemGroup>
          {items.map((item) => (
            // Only render if no permission required or user has permission
            (item.permission === null || can(item.permission)) && (
              renderSidebarItem(item.path, item.icon, item.label)
            )
          ))}
          <Sidebar.Item
            as="div"
            icon={HiLogout}
            className="cursor-pointer"
            onClick={handleLogout}
          >
            <span
              className={classNames("ml-2", {
                hidden: !isHovered && !isOpenOnSmallScreens,
              })}
            >
              Logout
            </span>
          </Sidebar.Item>
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    );
  };

  return (
    <nav
      className={classNames("sidebar duration-300 ease-in-out h-full", {
        "sidebar-collapsed lg:w-16": !isHovered && !isOpenOnSmallScreens,
        "sidebar-expanded lg:w-64": isHovered || isOpenOnSmallScreens,
        "w-full absolute z-50": isOpenOnSmallScreens,
      })}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Sidebar
        theme={theme}
        className="bg-bg border-r border-r-gray-200 overflow-hidden dark:border-r-gray-700 rounded-none fixed left-0 h-[calc(100vh-80px)] mt-20"
      >
        <div
          className={classNames(
            "flex flex-col py-4 border-b sidebar-transition",
            {
              "items-start": !isHovered && !isOpenOnSmallScreens,
              "items-center": isHovered || isOpenOnSmallScreens,
            }
          )}
        >
          {loading ? (
            <output className="animate-pulse flex flex-col items-center">
              <svg
                className={classNames("mb-3 text-gray-200 dark:text-gray-700", {
                  "w-8 h-8 lg:w-10 lg:h-10":
                    !isHovered && !isOpenOnSmallScreens,
                  "w-16 h-16": isHovered || isOpenOnSmallScreens,
                })}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg>
              {(isHovered || isOpenOnSmallScreens) && (
                <>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-3"></div>
                  <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </>
              )}
            </output>
          ) : (
            <>
              <div
                className={classNames(
                  "rounded-full bg-gray-200 flex items-center justify-center text-xl text-gray-700 transition-all duration-300",
                  {
                    "w-8 h-8 lg:w-10 lg:h-10":
                      !isHovered && !isOpenOnSmallScreens,
                    "w-16 h-16": isHovered || isOpenOnSmallScreens,
                  }
                )}
              >
                {getInitials(user?.name ?? "")}
              </div>
              {(isHovered || isOpenOnSmallScreens) && (
                <>
                  <p className="mt-2 font-semibold text-primary dark:text-gray-100 hidden lg:block">
                    {user?.name}
                  </p>
                  <p className="text-sm text-gray-500 hidden lg:block">
                    {user?.email}
                  </p>
                </>
              )}
            </>
          )}
        </div>

        <div className="flex flex-col justify-between py-2 rounded-none">
          <div>
            {renderSidebarItems()}
          </div>
        </div>
      </Sidebar>
    </nav>
  );
};

export default MainSidebar;
