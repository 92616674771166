import { useContext } from "react";
import { SidebarContext } from "./SidebarContext";

export function useSidebarContext() {
  const context = useContext(SidebarContext);

  if (context === undefined) {
    throw new Error(
      "useSidebarContext must be used within the SidebarProvider!"
    );
  }

  return context;
}
