import React from 'react';
import { Accordion, AccordionContent, AccordionPanel, AccordionTitle, Button } from 'flowbite-react';
import { ChatLog } from '../../../utils/types/chat';
import { extractNameAndEmail } from '../utils/messageHelpers';
import { usePermissions } from '../../../utils/hooks/usePermissions';

interface UserDetailsProps {
  log: ChatLog | null;
  isHighlighted: boolean;
  isIntervened: boolean;
  onIntervene: () => void;
  onRelease: () => void;
  isAssignedToOtherAgent: boolean;
  assignedAgentName: string;
  requiresHumanAssistance?: boolean;
}

export const UserDetails: React.FC<UserDetailsProps> = ({
  log,
  isIntervened,
  onIntervene,
  onRelease,
  isAssignedToOtherAgent,
  assignedAgentName,
  requiresHumanAssistance = false,
}: Readonly<UserDetailsProps>) => {
  const { can } = usePermissions();
  const canTakeOver = can('chat_takeover');

  if (!log) {
    return (
      <div className="text-gray-500 dark:text-gray-400 text-center p-4">
        Select a chat to view details
      </div>
    );
  }

  const { name, email } = extractNameAndEmail(log.message || '');

  return (
    <div className="pt-4">
      <Accordion>
        <AccordionPanel>
          <AccordionTitle className="text-gray-800 dark:text-gray-200">
            User Details
          </AccordionTitle>
          <AccordionContent>
            <div className="flex flex-col space-y-4 text-gray-800 dark:text-gray-200">
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <p className="break-words dark:text-gray-100">
                  <strong>Name:</strong> {name || 'Not provided'}
                </p>
              </div>

              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <p className="break-words dark:text-gray-100">
                  <strong>Email:</strong> {email || 'Not provided'}
                </p>
              </div>

              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <p className="break-words dark:text-gray-100">
                  <strong>Country:</strong> {log.country || 'Not provided'}
                </p>
              </div>

              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <p className="break-words dark:text-gray-100">
                  <strong>Rating:</strong> {log.rating || 'Not rated'}
                </p>
              </div>

              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <p className="break-words dark:text-gray-100">
                  <strong>Feedback:</strong> {log.feedback || 'No feedback'}
                </p>
              </div>

              {canTakeOver && (
                <>
                  {!isIntervened && !isAssignedToOtherAgent && requiresHumanAssistance && (
                    <Button
                      color="primary"
                      onClick={onIntervene}
                      className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg transition-colors"
                      disabled={isAssignedToOtherAgent}
                    >
                      Take Over Chat
                    </Button>
                  )}

                  {isAssignedToOtherAgent && (
                    <div className="text-yellow-600 dark:text-yellow-400 font-semibold text-center">
                      Chat assigned to {assignedAgentName}
                    </div>
                  )}

                  {isIntervened && (
                    <>
                      <div className="text-green-600 dark:text-green-400 font-semibold text-center">
                        Agent Chat in progress
                      </div>
                      <Button
                        color="warning"
                        onClick={onRelease}
                        className="w-full bg-yellow-500 hover:bg-yellow-600 dark:bg-yellow-600 dark:hover:bg-yellow-700"
                      >
                        Release Chat
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
          </AccordionContent>
        </AccordionPanel>
      </Accordion>
    </div>
  );
}
