import React, { useState, useEffect, useCallback } from "react";
import { Card, Button, Label, Spinner } from "flowbite-react";
import { HiCog } from "react-icons/hi";
import MainLayout from "../../layouts/MainLayout";
import { useAuth } from "../../utils/helpers/authWrapper";
import CustomToggle from "../../components/Toggle";
import { updateUserPreferences } from "../../api/settings";
import { toast, ToastContainer } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';
import 'react-toastify/dist/ReactToastify.css';
import { requestNotificationPermission } from "../../utils/notifications";
import { Breadcrumb } from '../../components/Breadcrumb';

interface PreferenceForm {
  desktopNotification: boolean;
  emailNotification: boolean;
  callRecording: boolean;
  callActivationOnLogin: boolean;
  defaultLanguage: string;
}

interface NotificationPermissionState {
  granted: boolean;
  denied: boolean;
}

const Preference: React.FC = () => {
  const { user } = useAuth();
  const [form, setForm] = useState<PreferenceForm>({
    desktopNotification: false,
    emailNotification: false,
    callRecording: false,
    callActivationOnLogin: false,
    defaultLanguage: "en",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState<NotificationPermissionState>({
    granted: false,
    denied: false
  });

  const checkNotificationPermission = useCallback(async () => {
    if (!("Notification" in window)) {
      toast.error("This browser does not support desktop notifications");
      return;
    }

    const permission = await Notification.permission;
    setNotificationPermission({
      granted: permission === "granted",
      denied: permission === "denied"
    });
  }, []);

  useEffect(() => {
    checkNotificationPermission();
  }, [checkNotificationPermission]);

  useEffect(() => {
    if (user) {
      setForm({
        desktopNotification: user.desktop_notification_enabled,
        emailNotification: user.email_notification_enabled,
        callRecording: user.call_recording_enabled,
        callActivationOnLogin: user.call_activation_enabled,
        defaultLanguage: user.default_language.toLowerCase(),
      });
      setIsLoading(false);
    }
  }, [user]);

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | boolean,
    id?: string
  ) => {
    if (typeof event === "boolean" && id === "desktopNotification") {
      if (event) {
        const permissionGranted = await requestNotificationPermission();
        if (!permissionGranted) {
          toast.error("Notification permission denied. Please enable notifications in your browser settings.");
          return;
        }
      }
    }

    if (typeof event === "boolean") {
      setForm((prevForm) => ({
        ...prevForm,
        [id as string]: event,
      }));
    } else {
      const { name, value, type } = event.target;
      const inputValue =
        type === "checkbox"
          ? (event.target as HTMLInputElement).checked
          : value;
      setForm((prevForm) => ({
        ...prevForm,
        [name]: inputValue,
      }));
    }
  };

  const showTestNotification = useCallback(() => {
    if (Notification.permission === "granted") {
      new Notification("Test Notification", {
        body: "This is a test notification from Rupeni",
        icon: "/path/to/your/icon.png", // Add your app icon path
      });
    }
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (user) {
      setIsSubmitting(true);
      try {
        const preferences = {
          desktop_notification_enabled: form.desktopNotification,
          call_activation_enabled: form.callActivationOnLogin,
          email_notification_enabled: form.emailNotification,
          call_recording_enabled: form.callRecording,
          default_language: form.defaultLanguage,
          id: user.id
        };
        const result = await updateUserPreferences(preferences);
        if (result === "Success") {
          toast.success('Preferences updated successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error('Failed to update preferences.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } catch (error) {
        console.error("Failed to update preferences:", error);
        toast.error('An error occurred while updating preferences.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  if (isLoading) {
    return (
      <MainLayout>
        <div className="flex justify-center items-center h-screen">
          <Spinner size="xl" />
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <div className="px-4 py-8">
            <Breadcrumb 
              items={[
                { label: 'Preferences', path: '/settings/preferences' }
              ]} 
            />
            <Card>
              <div className="flex items-center gap-4 mb-6">
                <HiCog className="w-6 h-6 md:w-8 md:h-8 text-gray-800 dark:text-gray-400" />
                <h1 className="text-xl md:text-2xl font-bold dark:text-white">Preferences</h1>
              </div>
              <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
                {[
                  { id: "desktopNotification", label: "Desktop Pop-up notification", description: "Enable alerts for incoming calls, chats, or updates even when you're using other applications. Browser or system notification permissions may be required for this feature to work.", extra: form.desktopNotification && notificationPermission.granted && (
                    <Button
                      size="sm"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        showTestNotification();
                      }}
                      className="mt-2 text-sm bg-gray-100 text-gray-700 hover:bg-gray-200"
                    >
                      Send Test Notification
                    </Button>
                  ) },
                  { id: "callActivationOnLogin", label: "Call Activation on Login", description: "Calls will only be active and visible when the user is logged into the Rupeni dashboard. The call feature will automatically be disabled when the user is not logged in or inactive." },
                  { id: "emailNotification", label: "Email notification", description: "Toggle to receive or disable email alerts for weekly reports and system updates." },
                  { id: "callRecording", label: "Call recording", description: "Automatically delete all call recordings after 30 days" },
                ].map((item) => (
                  <div key={item.id}>
                    <div className="flex flex-row sm:items-center justify-between mb-2">
                      <Label
                        htmlFor={item.id}
                        className="font-medium text-base md:text-lg mb-2 sm:mb-0"
                      >
                        {item.label}
                      </Label>
                      <CustomToggle
                        id={item.id}
                        label=""
                        checked={form[item.id as keyof PreferenceForm] as boolean}
                        onChange={(checked) => handleInputChange(checked, item.id)}
                        disabled={item.id === "desktopNotification" && notificationPermission.denied}
                      />
                    </div>
                    <p className="text-sm text-gray-500">{item.description}</p>
                    {item.extra}
                    {item.id === "desktopNotification" && notificationPermission.denied && (
                      <p className="text-sm text-red-500 mt-1">
                        Notifications are blocked. Please enable them in your browser settings.
                      </p>
                    )}
                  </div>
                ))}

                <div>
                  <Label
                    htmlFor="defaultLanguage"
                    className="block font-medium text-base md:text-lg mb-2"
                  >
                    Default Language Selection
                  </Label>
                  <p className="text-sm text-gray-500 mb-2">
                    Choose the primary language for the dashboard and communication
                    interface. All system messages and notifications will be
                    displayed in the selected language.
                  </p>
                  <select
                    id="defaultLanguage"
                    name="defaultLanguage"
                    value={form.defaultLanguage}
                    onChange={handleInputChange}
                    className="w-full sm:w-1/2 md:w-1/4 px-3 py-2 text-gray-700 border-2 border-gray-700 rounded-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                  >
                    <option value="en">English</option>
                    <option value="es">Español</option>
                    <option value="fr">Français</option>
                  </select>
                </div>
              </form>
            </Card>

            <div className="mt-6">
              <Button
                type="submit"
                disabled={isSubmitting}
                className="w-full sm:w-auto rounded-md bg-[#006AC1] hover:bg-[#006AC1]"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  handleSubmit(e as unknown as React.FormEvent<HTMLFormElement>);
                }}
              >
                {isSubmitting ? <Spinner size="sm" /> : "Save Changes"}
              </Button>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ zIndex: 9999999999999 }}
      />
    </MainLayout>
  );
};

export default Preference;
