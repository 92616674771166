import React, { useRef, useEffect } from 'react';
import { Button, TextInput } from 'flowbite-react';
import { MessageInputProps } from '../types';

export function MessageInput({ value, onChange, onSend, disabled = false }: Readonly<MessageInputProps>) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!disabled) {
      inputRef.current?.focus();
    }
  }, [disabled]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSend();
    }
  };

  return (
    <div className="flex items-center space-x-2 w-full">
      <TextInput
        ref={inputRef}
        type="text"
        placeholder="Type your message..."
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        className="flex-grow dark:bg-gray-700 dark:text-white dark:border-gray-600"
      />
      <Button 
        onClick={onSend}
        disabled={disabled || !value.trim()}
      >
        Send
      </Button>
    </div>
  );
} 