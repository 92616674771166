import React from "react";
import { Card, ListGroup } from "flowbite-react";
import {
  HiDatabase,
  HiDocumentText,
  HiLightBulb,
  HiExclamation,
  HiCheck,
  HiDocument,
  HiTable,
  HiDocumentDuplicate,
} from "react-icons/hi";
import { motion } from "framer-motion";
import MainLayout from "../../layouts/MainLayout";
import { Link } from "react-router-dom";

const TrainingDocs: React.FC = () => {
  return (
    <MainLayout>
      <motion.div
        className="px-4 pt-6 max-w mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="mb-4">
          <Link
            to="/documentation"
            className="text-purple-600 hover:text-purple-700 dark:text-purple-400"
          >
            ← Back to Documentation
          </Link>
        </div>

        <Card className="p-6 shadow-lg dark:bg-gray-800">
          <div className="flex items-center gap-3 mb-6">
            <HiDatabase className="w-8 h-8 text-purple-600 dark:text-purple-400" />
            <h1 className="text-3xl font-bold text-gray-800 dark:text-white">
              AI Training Guide
            </h1>
          </div>

          <p className="text-gray-600 dark:text-gray-300 mb-8">
            Follow this comprehensive guide to train your AI chatbot effectively. Proper training ensures your chatbot provides accurate, relevant responses that align with your business needs.
          </p>

          <div className="prose dark:prose-invert max-w-none space-y-12">
            <section>
              <h2 className="text-2xl font-semibold mb-6">Before You Begin</h2>
              <Card className="dark:bg-gray-700">
                <div className="space-y-4">
                  <div>
                    <h3 className="font-medium text-gray-800 dark:text-white">What You'll Need:</h3>
                    <ul className="list-disc pl-6 mt-2 text-gray-600 dark:text-gray-300">
                      <li>Your company's documentation, FAQs, or knowledge base in digital format</li>
                      <li>Clear understanding of your most common customer queries</li>
                      <li>Access to your Rupeni dashboard</li>
                    </ul>
                  </div>
                </div>
              </Card>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-6 flex items-center gap-2 text-gray-800 dark:text-white">
                <HiDocumentText className="w-6 h-6" />
                Supported File Formats
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <Card className="dark:bg-gray-700">
                  <div className="space-y-2">
                    <h3 className="font-medium text-gray-800 dark:text-white flex items-center gap-2">
                      <HiDocument className="w-5 h-5 text-purple-600" />
                      PDF Documents (.pdf)
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      Upload PDF files containing your company's documentation,
                      FAQs, or knowledge base articles.
                    </p>
                  </div>
                </Card>

                <Card className="dark:bg-gray-700">
                  <div className="space-y-2">
                    <h3 className="font-medium text-gray-800 dark:text-white flex items-center gap-2">
                      <HiDocumentDuplicate className="w-5 h-5 text-green-600" />
                      Word Documents (.docx)
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      Microsoft Word documents for formatted content,
                      documentation, and detailed guides.
                    </p>
                  </div>
                </Card>

                <Card className="dark:bg-gray-700">
                  <div className="space-y-2">
                    <h3 className="font-medium text-gray-800 dark:text-white flex items-center gap-2">
                      <HiTable className="w-5 h-5 text-blue-600" />
                      CSV Files (.csv)
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      Use CSV files for structured Q&A pairs or product
                      information.
                    </p>
                  </div>
                </Card>

                <Card className="dark:bg-gray-700">
                  <div className="space-y-2">
                    <h3 className="font-medium text-gray-800 dark:text-white flex items-center gap-2">
                      <HiDocumentText className="w-5 h-5 text-gray-600" />
                      Text Files (.txt)
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      Plain text files for general information and unstructured
                      content.
                    </p>
                  </div>
                </Card>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-6">Step-by-Step Training Guide</h2>
              <div className="space-y-8">
                <Card className="dark:bg-gray-700">
                  <div className="space-y-4">
                    <div>
                      <h3 className="font-medium text-gray-800 dark:text-white">Step 1: Prepare Your Training Data</h3>
                      <p className="text-gray-600 dark:text-gray-300 mb-2">
                        Before uploading, ensure your documents are:
                      </p>
                      <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
                        <li>Up-to-date and accurate</li>
                        <li>Written in clear, simple language</li>
                        <li>Free of sensitive information</li>
                        <li>Organized by topic or category</li>
                      </ul>
                    </div>
                  </div>
                </Card>

                <Card className="dark:bg-gray-700">
                  <div className="space-y-4">
                    <div>
                      <h3 className="font-medium text-gray-800 dark:text-white">Step 2: Upload Your Documents</h3>
                      <p className="text-gray-600 dark:text-gray-300 mb-2">
                        Follow these steps to upload your training materials:
                      </p>
                      <ol className="list-decimal pl-6 text-gray-600 dark:text-gray-300">
                        <li>Log into your Rupeni dashboard</li>
                        <li>Navigate to the Training section</li>
                        <li>Click "Upload Documents" or drag and drop files</li>
                        <li>Wait for the processing to complete</li>
                      </ol>
                    </div>
                  </div>
                </Card>

                <Card className="dark:bg-gray-700">
                  <div className="space-y-4">
                    <div>
                      <h3 className="font-medium text-gray-800 dark:text-white">Step 3: Review and Test</h3>
                      <p className="text-gray-600 dark:text-gray-300 mb-2">
                        After training is complete:
                      </p>
                      <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
                        <li>Test common customer questions</li>
                        <li>Verify response accuracy</li>
                        <li>Check for any knowledge gaps</li>
                        <li>Make adjustments as needed</li>
                      </ul>
                    </div>
                  </div>
                </Card>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-6 flex items-center gap-2 text-gray-800 dark:text-white">
                <HiLightBulb className="w-6 h-6" />
                Best Practices
              </h2>
              <div className="grid md:grid-cols-2 gap-6">
                <Card className="dark:bg-gray-700">
                  <h3 className="text-lg font-medium mb-3 flex items-center gap-2">
                    <HiCheck className="text-green-500" />
                    Do's
                  </h3>
                  <ListGroup className="space-y-2">
                    <ListGroup.Item className="bg-transparent">
                      Keep training data clear and concise
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent">
                      Include common questions and variations
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent">
                      Update training data regularly
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent">
                      Test chatbot responses after training
                    </ListGroup.Item>
                  </ListGroup>
                </Card>

                <Card className="dark:bg-gray-700">
                  <h3 className="text-lg font-medium mb-3 flex items-center gap-2">
                    <HiExclamation className="text-red-500" />
                    Don'ts
                  </h3>
                  <ListGroup className="space-y-2">
                    <ListGroup.Item className="bg-transparent">
                      Upload irrelevant or outdated content
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent">
                      Include sensitive or personal information
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent">
                      Use complex technical jargon unnecessarily
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent">
                      Overload with redundant information
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-6">Troubleshooting</h2>
              <Card className="dark:bg-gray-700">
                <div className="space-y-4">
                  <div>
                    <h3 className="font-medium text-gray-800 dark:text-white">Common Issues</h3>
                    <div className="space-y-3 mt-2">
                      <div>
                        <p className="text-gray-800 dark:text-white font-medium">Inaccurate Responses?</p>
                        <p className="text-gray-600 dark:text-gray-300">
                          • Review and update your training materials<br />
                          • Add more specific examples<br />
                          • Check for conflicting information
                        </p>
                      </div>
                      <div>
                        <p className="text-gray-800 dark:text-white font-medium">Processing Takes Too Long?</p>
                        <p className="text-gray-600 dark:text-gray-300">
                          • Reduce file sizes<br />
                          • Split large documents into smaller ones<br />
                          • Ensure stable internet connection
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </section>
          </div>
        </Card>
      </motion.div>
    </MainLayout>
  );
};

export default TrainingDocs;
