import { Plan } from '../utils/types/paymentplans';
import axios from './axiosConfig';
import { isAxiosError } from 'axios';
import { Statement } from '../pages/Billing/index';

interface StatementsResponse {
  statements: Statement[];
}

// Add interface for the payment response
interface AddPaymentResponse {
  success: boolean;
  message?: string;
  payment?: {
    id: number;
    user_id: number;
    amount: number;
    status: string;
    created_at: string;
  };
}


// Add interface for the update payment response
interface UpdatePaymentResponse {
  payment: {
    id: number;
    user_id: number;
    amount: number;
    status: string;
    masked_card_number: string | null;
    token: string | null;
    created_at: string;
  };
}

// Add interface for payment history
interface Payment {
  id: number;
  user_id: number;
  amount: number;
  status: string;
  masked_card_number: string | null;
  token: string | null;
  created_at: string;
}

interface PaymentsResponse {
  payments: Payment[];
}

export const getPlans = async (): Promise<Plan[] | null> => {
  try {
    const response = await axios.get<Plan[]>('/get-all-plans');
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error('Error fetching plans:', error.response.data);
    } else {
      console.error('Error fetching plans:', error);
    }
    return null;
  }
};

export const getStatementsByUserId = async (userId: number): Promise<StatementsResponse | null> => {
  try {
    const response = await axios.post<StatementsResponse>('/get-statements-by-user-id', {
      user_id: userId
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error('Error fetching statements:', error.response.data);
    } else {
      console.error('Error fetching statements:', error);
    }
    return null;
  }
};

export const initiatePayment = async (customerName: string, amount: string) => {
  try {
    const paymentData = {
      merchantId: "rupeni",
      customerName,
      amount,
      appId: "RUP",
      orderId: Date.now().toString(),
      returnUrl: "https://staging.rupeni.com/billing",
      currency: "FJD"
    };

    const response = await axios.post('https://payments.vaizee.com/pay', paymentData, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error('Error initiating payment:', error.response.data);
    } else {
      console.error('Error initiating payment:', error);
    }
    throw error;
  }
};

export const addNewPaymentByUserId = async (
  userId: number, 
  amount: number, 
  status: 'pending' | 'complete' | 'failed' = 'pending'
): Promise<AddPaymentResponse | null> => {
  try {
    const response = await axios.post<AddPaymentResponse>('/add-new-payment-by-user-id', {
      user_id: userId,
      amount,
      status
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error('Error adding new payment:', error.response.data);
    } else {
      console.error('Error adding new payment:', error);
    }
    return null;
  }
};

export const updatePaymentStatus = async (
  paymentId: number,
  status: 'pending' | 'complete' | 'declined',
  maskedCardNumber?: string,
  token?: string
): Promise<UpdatePaymentResponse | null> => {
  try {
    const response = await axios.post<UpdatePaymentResponse>('/update-payment-status-by-id', {
      id: paymentId,
      status,
      masked_card_number: maskedCardNumber,
      token
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error('Error updating payment status:', error.response.data);
    } else {
      console.error('Error updating payment status:', error);
    }
    return null;
  }
};

export const getPaymentsByUserId = async (userId: number): Promise<PaymentsResponse | null> => {
  try {
    const response = await axios.post<PaymentsResponse>('/get-payments-by-user-id', {
      user_id: userId
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error('Error fetching payments:', error.response.data);
    } else {
      console.error('Error fetching payments:', error);
    }
    return null;
  }
};

