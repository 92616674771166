
import { Mastercard, Visa, Amex, Discover, Generic } from 'react-payment-logos/dist/flat';
import { IconType } from 'react-icons';

interface CardTypeInfo {
  name: string;
  icon: IconType;
}

export function getCardTypeInfo(cardNumber: string | null): CardTypeInfo {
  if (!cardNumber) {
    return {
      name: 'Card',
      icon: Generic
    };
  }

  const cleanNumber = cardNumber.replace(/[^\d]/g, '');
  
  const cardTypes: Record<string, { pattern: RegExp, icon: IconType }> = {
    visa: {
      pattern: /^4/,
      icon: Visa
    },
    mastercard: {
      pattern: /^5[1-5]/,
      icon: Mastercard
    },
    amex: {
      pattern: /^3[47]/,
      icon: Amex
    },
    discover: {
      pattern: /^6(?:011|5)/,
      icon: Discover
    }
  };

  for (const [type, info] of Object.entries(cardTypes)) {
    if (info.pattern.test(cleanNumber)) {
      return {
        name: type.charAt(0).toUpperCase() + type.slice(1),
        icon: info.icon
      };
    }
  }

  return {
    name: 'Card',
    icon: Generic
  };
}
  