import { useState, useCallback, useRef, useEffect } from "react";
import { ChatLog, ChatLogsResponse } from "../../../utils/types/chat";
import { getChatLogs, releaseChat, takeOverChat } from "../../../api/chat";
import { useAuth } from "../../../utils/helpers/authWrapper";
import { useNavigate } from "react-router-dom";
import { splitMessages } from "../utils/messageHelpers";
import { HumanAssistanceRequest } from "../types";
import { useWebSocketContext } from "../../../utils/context";
import { useAudio } from "./useAudio";
import { usePermissions } from '../../../utils/hooks/usePermissions';

interface FetchParams {
  search?: string;
  domain_id?: number;
  selectedLogId?: string;
}

interface WebSocketMessage {
  type:
    | "user_message"
    | "assistant_message"
    | "human_assistance_request"
    | "new_call_request"
    | "chat_assigned";
  message?: string;
  sessionID?: string;
  domain?: string;
  data?: {
    session_id?: string;
    domain?: string;
    name?: string;
  };
}

export function useChat() {
  const [chatLogs, setChatLogs] = useState<ChatLogsResponse>([]);
  const [selectedLog, setSelectedLog] = useState<ChatLog | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [intervenedChats, setIntervenedChats] = useState<Set<string>>(
    new Set()
  );
  const [highlightedMessageIds, setHighlightedMessageIds] = useState<
    Set<string>
  >(new Set());
  const [chatMessages, setChatMessages] = useState<string[]>([]);
  const [lastHumanAssistanceRequest, setLastHumanAssistanceRequest] =
    useState<HumanAssistanceRequest | null>(null);
  const [humanAssistanceRequests, setHumanAssistanceRequests] = useState<Map<string, HumanAssistanceRequest>>(new Map());

  const currentOffsetRef = useRef(0);
  const isFetchingRef = useRef(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const { clearNotification, clearAllNotificationsForSession } = useWebSocketContext();
  const { stopSound } = useAudio();
  const { can } = usePermissions();
  const canTakeOver = can('chat_takeover');

  const POLLING_INTERVAL = 5000;

  const updateChatMessages = useCallback((log: ChatLog) => {
    const messages = splitMessages(log.message);
    setChatMessages(messages);
  }, []);

  const fetchChatLogs = useCallback(
    async (
      resetOffset = false,
      params: FetchParams = {},
      isPolling = false
    ) => {
      if (isFetchingRef.current) return;

      try {
        isFetchingRef.current = true;

        if (resetOffset && (params.search || params.domain_id)) {
          setIsLoading(true);
          currentOffsetRef.current = 0;
        } else {
          setIsLoadingMore(true);
        }

        const data = await getChatLogs({
          limit: 10,
          offset: isPolling ? 0 : currentOffsetRef.current,
          ...params,
        });

        if (data) {
          setChatLogs((prevItems) => {
            const existingLogs =
              resetOffset && (params.search || params.domain_id)
                ? []
                : prevItems;
            const logsMap = new Map(
              existingLogs.map((log) => [log.session_id, log])
            );

            if (isPolling) {
              data.forEach((newLog) => {
                logsMap.set(newLog.session_id, newLog);

                // If this is the selected log, update its messages
                if (
                  selectedLog?.session_id === newLog.session_id &&
                  newLog.message !== selectedLog.message
                ) {
                  setSelectedLog(newLog);
                  updateChatMessages(newLog);
                }
              });
            } else {
              data.forEach((newLog) => {
                const existing = logsMap.get(newLog.session_id);
                if (existing) {
                  if (existing.message !== newLog.message) {
                    logsMap.set(newLog.session_id, newLog);

                    // If this is the selected log, update its messages
                    if (selectedLog?.session_id === newLog.session_id) {
                      setSelectedLog(newLog);
                      updateChatMessages(newLog);
                    }
                  }
                } else {
                  logsMap.set(newLog.session_id, newLog);
                }
              });
            }

            const newLogs = Array.from(logsMap.values()).sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            );

            if (
              !isPolling &&
              (!resetOffset || !(params.search || params.domain_id))
            ) {
              currentOffsetRef.current += data.length;
            }

            return newLogs;
          });

          if (!isPolling) {
            setHasMore(data.length === 10);
          }
        } else {
          if (!isPolling) {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.error("Error fetching chat logs:", error);
        if (!isPolling) {
          setHasMore(false);
        }
      } finally {
        setIsLoading(false);
        setIsLoadingMore(false);
        isFetchingRef.current = false;
      }
    },
    [selectedLog, updateChatMessages]
  );

  useEffect(() => {
    const pollInterval = setInterval(() => {
      fetchChatLogs(false, {}, true);
    }, POLLING_INTERVAL);

    return () => clearInterval(pollInterval);
  }, [fetchChatLogs]);

  useEffect(() => {
    fetchChatLogs(false);
  }, [fetchChatLogs]);

  const fetchUpdatedLog = useCallback(async (sessionId: string) => {
    try {
      const updatedLogs = await getChatLogs({ limit: 10, offset: 0 });
      if (updatedLogs && updatedLogs.length > 0) {
        const relevantLog = updatedLogs.find(
          (log) => log.session_id === sessionId
        );

        if (relevantLog) {
          setChatLogs((prevLogs) => {
            const updatedLogIndex = prevLogs.findIndex(
              (log) => log.session_id === sessionId
            );
            if (updatedLogIndex !== -1) {
              const newLogs = [...prevLogs];
              newLogs[updatedLogIndex] = relevantLog;
              return newLogs;
            }
            return [relevantLog, ...prevLogs];
          });

          setSelectedLog((prevSelectedLog) => {
            if (prevSelectedLog && prevSelectedLog.session_id === sessionId) {
              return relevantLog;
            }
            return prevSelectedLog;
          });
        }
      }
    } catch (error) {
      console.error("Error fetching updated log:", error);
    }
  }, []);

  const handleReleaseChat = async (session_id: string) => {
    if (!canTakeOver) return;

    const chatLog = chatLogs.find((log) => log.session_id === session_id);
    if (!chatLog) {
      console.error("Chat log not found for session:", session_id);
      return;
    }

    const { user } = auth;
    console.log("Attempting to release chat:", {
      session_id,
      userId: user?.id,
    });

    try {
      const success = await releaseChat(
        chatLog.domain,
        session_id,
        user?.id.toString() ?? ""
      );
      console.log("Release chat response:", success);

      if (success) {
        setIntervenedChats((prev) => {
          const newSet = new Set(prev);
          newSet.delete(session_id);
          return newSet;
        });

        // Clear ALL notifications for this session
        clearAllNotificationsForSession(session_id);
        stopSound();
        setLastHumanAssistanceRequest(null);
        
        // Also clear from human assistance requests
        setHumanAssistanceRequests(prev => {
          const newMap = new Map(prev);
          newMap.delete(session_id);
          return newMap;
        });
      }
    } catch (error) {
      console.error("Error releasing chat:", error);
    }
  };

  const handleIntervene = async (session_id: string) => {
    if (!canTakeOver) return;
  
    const chatLog = chatLogs.find((log) => log.session_id === session_id);
    if (!chatLog) {
      console.error("Chat log not found for session:", session_id);
      return;
    }
  
    const { user } = auth;
    console.log("Attempting to intervene:", { session_id, userId: user?.id });
  
    try {
      const success = await takeOverChat(
        chatLog.domain,
        session_id,
        user?.id.toString() ?? ""
      );
      console.log("Intervene response:", success);
  
      if (success) {
        // Stop sound first before making any state changes
        stopSound();
        
        setIntervenedChats((prev) => new Set(prev).add(session_id));
        
        // Remove from highlighted messages
        setHighlightedMessageIds((prev) => {
          const newSet = new Set(prev);
          newSet.delete(session_id);
          return newSet;
        });
  
        // Clear notification and reset human assistance request
        clearNotification(session_id);
        setLastHumanAssistanceRequest(null);
        
        // Remove from human assistance requests
        setHumanAssistanceRequests(prev => {
          const newMap = new Map(prev);
          newMap.delete(session_id);
          return newMap;
        });
  
        if (selectedLog) {
          const updatedLog = { ...selectedLog };
          setSelectedLog(updatedLog);
          updateChatMessages(updatedLog);
        }
  
        navigate("/chats", { replace: true, state: {} });
      }
    } catch (error) {
      console.error("Error intervening:", error);
    }
  };

  const handleSendMessage = useCallback(
    (messageText: string) => {
      if (!selectedLog?.session_id || !lastHumanAssistanceRequest) return;

      // Immediately append the message to the current chat
      const newMessage = `Support: ${messageText}`;
      setChatMessages((prev) => [...prev, newMessage]);

      // Update the selected log's message
      if (selectedLog) {
        const updatedMessage =
          selectedLog.message + "\n\nSupport: " + messageText;

        // Update both the logs list and selected log
        setChatLogs((prevLogs) =>
          prevLogs.map((log) =>
            log.session_id === selectedLog.session_id
              ? { ...log, message: updatedMessage }
              : log
          )
        );

        setSelectedLog({
          ...selectedLog,
          message: updatedMessage,
        });
      }
    },
    [selectedLog, lastHumanAssistanceRequest]
  );

  // Modify the WebSocket message handler to respect viewer permissions
  const handleWebSocketMessage = useCallback(
    (data: WebSocketMessage) => {
      if (!data) return;
      if (!canTakeOver) return;

      try {
        const sessionId = data.sessionID || data.data?.session_id;
        if (!sessionId) return;

        // Handle chat assignment
        if (data.type === "chat_assigned") {
          setHighlightedMessageIds(prev => {
            const newSet = new Set(prev);
            newSet.delete(sessionId);
            return newSet;
          });
          
          setHumanAssistanceRequests(prev => {
            const newMap = new Map(prev);
            newMap.delete(sessionId);
            return newMap;
          });

          setLastHumanAssistanceRequest(current => 
            current?.sessionID === sessionId ? null : current
          );

          // Stop sound if no more highlighted messages
          if (highlightedMessageIds.size <= 1 && highlightedMessageIds.has(sessionId)) {
            stopSound();
          }
          
          return;
        }

        // For human assistance request
        if (data.type === "human_assistance_request") {
          if (canTakeOver && data.domain) {
            // First stop any existing sound to reset the isPlayingRef
            stopSound();
            
            setHighlightedMessageIds(prev => new Set(prev).add(sessionId));
            
            const newRequest: HumanAssistanceRequest = {
              domain: data.domain,
              messageID: Date.now(),
              reason: data.message || 'No reason provided',
              sessionID: sessionId,
              type: 'human_assistance_request'
            };

            setHumanAssistanceRequests(prev => {
              const newMap = new Map(prev);
              newMap.set(sessionId, newRequest);
              return newMap;
            });
            
            setLastHumanAssistanceRequest(newRequest);
          }
          return;
        }

        // For chat messages
        if (data.type === "user_message" || data.type === "assistant_message") {
          const messagePrefix = data.type === "user_message" ? "User: " : "Assistant: ";
          const newMessage = `${messagePrefix}${data.message || ''}`;

          // Update messages if this is the currently selected chat
          if (selectedLog?.session_id === sessionId) {
            setChatMessages(prev => [...prev, newMessage]);

            if (selectedLog) {
              const updatedMessage = selectedLog.message + "\n\n" + newMessage;
              setSelectedLog({
                ...selectedLog,
                message: updatedMessage,
              });
            }
          }

          // Always update the chat logs list
          setChatLogs(prevLogs =>
            prevLogs.map(log => {
              if (log.session_id === sessionId) {
                return {
                  ...log,
                  message: log.message + "\n\n" + newMessage,
                };
              }
              return log;
            })
          );

          // If this is a human assistance request and user can take over,
          // ensure the message stays highlighted
          if (canTakeOver && humanAssistanceRequests.has(sessionId)) {
            setHighlightedMessageIds(prev => new Set(prev).add(sessionId));
          }
        }
      } catch (error) {
        console.error("Error handling WebSocket message:", error);
      }
    },
    [selectedLog, canTakeOver, humanAssistanceRequests, highlightedMessageIds, stopSound]
  );

  const pollForUpdates = useCallback(async () => {
    try {
      const recentData = await getChatLogs({ limit: 10, offset: 0 });
      if (recentData) {
        setChatLogs((prevLogs) => {
          const updatedLogs = prevLogs.map((log) => {
            const updatedLog = recentData.find(
              (recent) => recent.session_id === log.session_id
            );
            return updatedLog || log;
          });

          // Add any new logs
          const newLogs = recentData.filter(
            (log) =>
              !prevLogs.some(
                (existing) => existing.session_id === log.session_id
              )
          );

          return [...newLogs, ...updatedLogs]
            .sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
            .slice(0, 100);
        });

        // Update selected log if it exists in the new data
        if (selectedLog) {
          const updatedSelectedLog = recentData.find(
            (log) => log.session_id === selectedLog.session_id
          );
          if (
            updatedSelectedLog &&
            updatedSelectedLog.message !== selectedLog.message
          ) {
            setSelectedLog(updatedSelectedLog);
            updateChatMessages(updatedSelectedLog);
          }
        }
      }
    } catch (error) {
      console.error("Error polling for updates:", error);
    }
  }, [selectedLog, updateChatMessages]);

  // Clear highlights and assistance requests for viewers
  useEffect(() => {
    if (!canTakeOver) {
      setHighlightedMessageIds(new Set());
      setHumanAssistanceRequests(new Map());
      setLastHumanAssistanceRequest(null);
    }
  }, [canTakeOver]);

  return {
    chatLogs,
    selectedLog,
    isLoading,
    isLoadingMore,
    hasMore,
    intervenedChats,
    highlightedMessageIds: canTakeOver ? highlightedMessageIds : new Set(),
    chatMessages,
    lastHumanAssistanceRequest: canTakeOver ? lastHumanAssistanceRequest : null,
    setSelectedLog,
    setChatMessages,
    setChatLogs,
    setHighlightedMessageIds,
    setLastHumanAssistanceRequest,
    fetchChatLogs,
    fetchUpdatedLog,
    handleIntervene,
    handleReleaseChat,
    updateChatMessages,
    handleSendMessage,
    handleWebSocketMessage,
    pollForUpdates,
    setIntervenedChats,
    humanAssistanceRequests: canTakeOver ? humanAssistanceRequests : new Map(),
  };
}
