import { ChatCount, ChatLog } from '../utils/types/chat';

import axios from './axiosConfig';
import { isAxiosError } from 'axios';

export const getChatCounts = async (
    user_id: number
): Promise<ChatCount[]> => {
  try {
    const response = await axios.post<ChatCount[]>('/get-chat-count', {
      user_id
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error('Error fetching plans:', error.response.data);
    } else {
      console.error('Error fetching plans:', error);
    }
    return [];
  }
};

interface DateRangeParams {
  user_id: number;
  start_date?: string;
  end_date?: string;
}

export const getChatHistoryByDateRange = async (params: DateRangeParams): Promise<ChatLog[]> => {
  try {
    const response = await axios.post<ChatLog[]>('/chat-history-by-date-range', {
      user_id: params.user_id,
      ...(params.start_date && { start_date: params.start_date }),
      ...(params.end_date && { end_date: params.end_date })
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error('Error fetching chat history:', error.response.data);
    } else {
      console.error('Error fetching chat history:', error);
    }
    return [];
  }
};
