interface NotificationOptions {
  title: string;
  body: string;
  icon?: string;
  onClick?: () => void;
}

export const requestNotificationPermission = async (): Promise<boolean> => {
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notifications");
    return false;
  }

  if (Notification.permission === "granted") {
    return true;
  }

  if (Notification.permission !== "denied") {
    const permission = await Notification.requestPermission();
    return permission === "granted";
  }

  return false;
};

export const sendBrowserNotification = ({
  title,
  body,
  icon = "/favicon.ico",
  onClick
}: NotificationOptions): void => {
  if (!document.hasFocus() && Notification.permission === "granted") {
    const notification = new Notification(title, {
      body,
      icon,
      silent: false
    });

    if (onClick) {
      notification.onclick = () => {
        window.focus();
        onClick();
        notification.close();
      };
    }
  }
}; 