import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useWebSocketContext } from '../../utils/context';
import { useAudio } from '../../pages/Chats/hooks/useAudio';
import { usePermissions } from '../../utils/hooks/usePermissions';

const GlobalAlert: React.FC = () => {
  const location = useLocation();
  const { notifications, clearNotification } = useWebSocketContext();
  const [currentNotification, setCurrentNotification] = useState(notifications[0]);
  const navigate = useNavigate();
  const { playSound, stopSound } = useAudio();
  const intervalRef = useRef<number | null>(null);
  const { can } = usePermissions();
  const canTakeOver = can('chat_takeover');

  // Filter out call notifications if we're on the chat page
  const relevantNotifications = useMemo(() => {
    if (location.pathname === '/chats') {
      return notifications.filter(n => n.type !== 'new_call_request');
    }
    return notifications;
  }, [notifications, location.pathname]);

  useEffect(() => {
    setCurrentNotification(relevantNotifications[0]);
  }, [relevantNotifications]);

  useEffect(() => {
    if (currentNotification && canTakeOver) {
      playSound();
    } else {
      stopSound();
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [currentNotification, playSound, stopSound, canTakeOver]);

  // Don't show alerts for viewers or when on chat page
  if (!canTakeOver || !currentNotification || location.pathname === '/chats') {
    return null;
  }

  const handleDiscard = () => {
    stopSound();
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    clearNotification(currentNotification.id);
  };

  const handleGoToChat = () => {
    stopSound();
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    navigate('/chats', { 
      state: { 
        highlightedSessionId: currentNotification.sessionId,
        humanAssistanceRequest: {
          sessionID: currentNotification.sessionId,
          domain: currentNotification.domain,
          type: 'human_assistance_request',
          messageID: Date.now(),
          reason: currentNotification.message
        }
      } 
    });
    clearNotification(currentNotification.id);
  };

  return (
    <div className="fixed bottom-4 right-4 bg-gray-800 p-4 rounded-lg shadow-lg max-w-sm">
      <p className="mb-2 text-white">{currentNotification.message}</p>
      <div className="flex items-center mb-3">
        <svg className="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span className="text-white text-sm">
          Domain: {currentNotification.domain || 'Unknown'}
        </span>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-white text-sm">
          {relevantNotifications.length > 1 ? `+${relevantNotifications.length - 1} more` : ''}
        </span>
        <div className="flex space-x-2">
          <button onClick={handleGoToChat} className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 transition-colors">
            Go to Chat
          </button>
          <button onClick={handleDiscard} className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition-colors">
            Discard
          </button>
        </div>
      </div>
    </div>
  );
};

export default GlobalAlert;
