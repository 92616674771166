import { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useWebSocketContext } from "../../../utils/context";
import useWebSocket from "react-use-websocket";
import { HumanAssistanceRequest } from "../types";
import { useAudio } from "./useAudio";
import { useAuth } from "../../../utils/helpers/authWrapper";

interface WebSocketHandlerProps {
  setHighlightedMessageIds: (cb: (prev: Set<string>) => Set<string>) => void;
  setLastHumanAssistanceRequest: (
    request: HumanAssistanceRequest | null
  ) => void;
  setActiveCallSessions: (cb: (prev: Set<string>) => Set<string>) => void;
  fetchUpdatedLog: (sessionId: string) => Promise<void>;
  hasUserInteracted: boolean;
  intervenedChats?: Set<string>;
}

export function useWebSocketHandler({
  setHighlightedMessageIds,
  setLastHumanAssistanceRequest,
  setActiveCallSessions,
  fetchUpdatedLog,
  hasUserInteracted,
  intervenedChats = new Set(),
}: WebSocketHandlerProps) {
  const location = useLocation();
  const { listenerMessage } = useWebSocketContext();
  const { sendMessage: sendSupportMessage } = useWebSocket(
    "wss://staging-api.rupeni.com/chat?type=support"
  );
  const { playSound, stopSound } = useAudio();
  const lastMessageRef = useRef<string>("");
  const soundPlayingForSessionRef = useRef<string | null>(null);
  const { domains } = useAuth();

  const requestTimeoutsRef = useRef<Map<string, NodeJS.Timeout>>(new Map());

  // Helper function to check if domain is authorized
  const isDomainAuthorized = useCallback((domainToCheck: string) => {
    return domains?.some(domain => domain.domain === domainToCheck) ?? false;
  }, [domains]);

  const clearRequest = useCallback((sessionId: string) => {
    setHighlightedMessageIds((prev) => {
      const newSet = new Set(prev);
      newSet.delete(sessionId);
      return newSet;
    });
    setLastHumanAssistanceRequest(null);
    if (soundPlayingForSessionRef.current === sessionId) {
      stopSound();
      soundPlayingForSessionRef.current = null;
    }
  }, [setHighlightedMessageIds, setLastHumanAssistanceRequest, stopSound]);

  useEffect(() => {
    if (!listenerMessage?.data) return;

    try {
      if (typeof listenerMessage.data !== "string") {
        console.error("WebSocket message data is not a string");
        return;
      }

      if (lastMessageRef.current === listenerMessage.data) {
        return;
      }

      lastMessageRef.current = listenerMessage.data;
      const parsedData = JSON.parse(listenerMessage.data);
      const sessionId = parsedData.sessionID || parsedData.data?.session_id;
      const requestDomain = parsedData.domain || parsedData.data?.domain;

      // Skip if domain is not authorized
      if (!isDomainAuthorized(requestDomain)) {
        console.log(`Skipping request for unauthorized domain: ${requestDomain}`);
        return;
      }

      if (intervenedChats.has(sessionId)) {
        if (soundPlayingForSessionRef.current === sessionId) {
          stopSound();
          soundPlayingForSessionRef.current = null;
        }
        return;
      }

      if (location.pathname === "/chats") {
        if (parsedData.type === "human_assistance_request") {
          const targetSessionId = parsedData.sessionID;

          // Clear any existing timeout for this session
          if (requestTimeoutsRef.current.has(targetSessionId)) {
            clearTimeout(requestTimeoutsRef.current.get(targetSessionId));
          }

          setHighlightedMessageIds((prev) => {
            const newSet = new Set(prev);
            if (!prev.has(targetSessionId) && hasUserInteracted && !intervenedChats.has(targetSessionId)) {
              playSound(() => {
                // This callback will be called after 2 minutes
                clearRequest(targetSessionId);
              });
              soundPlayingForSessionRef.current = targetSessionId;
            }
            newSet.add(targetSessionId);
            return newSet;
          });

          setLastHumanAssistanceRequest(parsedData);
          fetchUpdatedLog(targetSessionId);

          // Set timeout to clear the request after 5 seconds
          const timeout = setTimeout(() => {
            clearRequest(targetSessionId);
            requestTimeoutsRef.current.delete(targetSessionId);
          }, 5 * 1000); // 5 seconds

          requestTimeoutsRef.current.set(targetSessionId, timeout);
        } else if (parsedData.type === "new_call_request") {
          const targetSessionId = parsedData.data.session_id;
          
          setHighlightedMessageIds((prev) => {
            const newSet = new Set(prev);
            newSet.add(targetSessionId);
            return newSet;
          });

          setActiveCallSessions((prev) => new Set(prev).add(targetSessionId));
          fetchUpdatedLog(targetSessionId);
        }
      }
    } catch (error) {
      console.error("Error handling WebSocket message:", error);
    }
  }, [
    listenerMessage,
    location.pathname,
    fetchUpdatedLog,
    setHighlightedMessageIds,
    setLastHumanAssistanceRequest,
    setActiveCallSessions,
    playSound,
    stopSound,
    intervenedChats,
    hasUserInteracted,
    clearRequest,
  ]);

  // Cleanup timeouts on unmount
  useEffect(() => {
    return () => {
      requestTimeoutsRef.current.forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, []);

  return {
    handleSendMessage: useCallback(
      (message: string, sessionId: string, domain: string) => {
        const messageData = {
          type: "human_message",
          domain: domain,
          sessionID: sessionId,
          message: message,
        };

        sendSupportMessage(JSON.stringify(messageData));
      },
      [sendSupportMessage]
    ),
  };
}
