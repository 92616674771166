import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HiLockClosed } from 'react-icons/hi';

const AccessDenied: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col justify-center items-center px-6 py-8">
      <div className="text-center">
        <HiLockClosed className="mx-auto h-24 w-24 text-gray-400 dark:text-gray-600" />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
          Access Denied
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">
          You don't have permission to access this page.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <button
            onClick={() => navigate(-1)}
            className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          >
            Go back
          </button>
          <button
            onClick={() => navigate('/dashboard')}
            className="text-sm font-semibold text-gray-900 dark:text-white"
          >
            Go to Dashboard <span aria-hidden="true">&rarr;</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied; 