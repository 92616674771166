import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { ChatLogItem } from "./ChatLogItem";
import Spinner from "../../../components/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { ChatSidebarProps } from "../types";
import { DomainData } from "../../../utils/types/domains";

function DomainSelector({
  selectedDomainId,
  domains,
  onSelect,
}: Readonly<{
  selectedDomainId: number | null;
  domains: DomainData[];
  onSelect: (id: number | null) => void;
}>) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative w-full mb-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full inline-flex justify-between items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      >
        {selectedDomainId === null
          ? "All Domains"
          : domains.find((d) => d.id === selectedDomainId)?.domain ??
            "All Domains"}
        <svg
          className="w-4 h-4 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute w-full mt-1 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg z-10 max-h-60 overflow-y-auto">
          <button
            onClick={() => {
              onSelect(null);
              setIsOpen(false);
            }}
            className="block w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 text-left"
          >
            All Domains
          </button>
          {domains.map((domain) => (
            <button
              key={domain.id}
              onClick={() => {
                onSelect(Number(domain.id));
                setIsOpen(false);
              }}
              className="block w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 text-left"
            >
              {domain.domain}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export const ChatSidebar: React.FC<ChatSidebarProps> = ({
  searchQuery,
  onSearchChange,
  selectedDomainId,
  domains,
  onDomainSelect,
  filteredAndSortedLogs,
  isLoading,
  isLoadingMore,
  hasMore,
  onLoadMore,
  selectedLog,
  highlightedMessageIds,
  activeCallSessions,
  onSelectLog,
  isMobile,
  assignedChats,
}: Readonly<ChatSidebarProps>) => {
  if (isMobile && selectedLog) return null;

  const renderChatContent = () => {
    if (isLoading && filteredAndSortedLogs.length === 0) {
      return (
        <div className="flex justify-center items-center h-full">
          <Spinner />
        </div>
      );
    }

    if (filteredAndSortedLogs.length === 0) {
      return (
        <div className="text-center text-gray-500 mt-4">No results found.</div>
      );
    }

    return filteredAndSortedLogs.map((log) => (
      <ChatLogItem
        key={log.session_id}
        log={log}
        isSelected={selectedLog?.session_id === log.session_id}
        isHighlighted={highlightedMessageIds.has(log.session_id)}
        onClick={() => onSelectLog(log)}
        activeCallSessions={activeCallSessions}
        assignedChats={assignedChats}
      />
    ));
  };

  return (
    <div className="w-full border-r border-gray-300 dark:border-gray-600 flex flex-col h-full overflow-hidden">
      <div className="p-4 space-y-4">
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <FaSearch className="w-4 h-4 text-gray-500 dark:text-gray-400" />
          </div>
          <input
            type="text"
            id="search"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search Messages"
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </div>

        <DomainSelector
          selectedDomainId={selectedDomainId}
          domains={domains}
          onSelect={onDomainSelect}
        />
      </div>

      <div id="scrollableDiv" className="flex-1 overflow-y-auto min-h-0">
        <InfiniteScroll
          dataLength={filteredAndSortedLogs.length}
          next={onLoadMore}
          hasMore={hasMore}
          loader={
            isLoadingMore && (
              <div className="py-4">
                <Spinner />
              </div>
            )
          }
          scrollableTarget="scrollableDiv"
          scrollThreshold={0.9}
          className="h-full"
          endMessage={
            <div className="text-center text-gray-500 py-4">
              No more chats to load.
            </div>
          }
        >
          {renderChatContent()}
        </InfiniteScroll>
      </div>
    </div>
  );
};
