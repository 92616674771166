import React from "react";
import { Card, Tabs, Label } from "flowbite-react";
import { HiCog, HiColorSwatch, HiChat,  HiVolumeUp } from "react-icons/hi";
import { motion } from "framer-motion";
import MainLayout from "../../layouts/MainLayout";
import { Link } from "react-router-dom";

const ChatWidgetDocs: React.FC = () => {
  return (
    <MainLayout>
      <motion.div 
        className="px-4 pt-6 max-w mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="mb-4">
          <Link 
            to="/documentation"
            className="text-purple-600 hover:text-purple-700 dark:text-purple-400"
          >
            ← Back to Documentation
          </Link>
        </div>

        <Card className="p-6 shadow-lg dark:bg-gray-800">
          <div className="flex items-center gap-3 mb-6">
            <HiCog className="w-8 h-8 text-purple-600 dark:text-purple-400" />
            <h1 className="text-3xl font-bold text-gray-800 dark:text-white">
              Chat Widget Configuration
            </h1>
          </div>

          <div className="mb-6">
            <p className="text-gray-600 dark:text-gray-300">
              Learn how to customize your chat widget's appearance and behavior. Follow these settings to create a personalized chat experience for your users.
            </p>
          </div>

          <Tabs aria-label="Chat widget configuration options">
            <Tabs.Item
              active
              title={
                <div className="flex items-center gap-2">
                  <HiChat className="h-5 w-5" />
                  <span>Basic Settings</span>
                </div>
              }
            >
              <div className="space-y-8">
                <section>
                  <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
                    Setting Up Your Chat Bot Identity
                  </h2>
                  <Card className="dark:bg-gray-700">
                    <div className="space-y-4">
                      <div>
                        <Label htmlFor="chatBotName" className="block text-sm font-medium text-gray-900 dark:text-white">
                          How to Set Chat Bot Name
                        </Label>
                        <div className="mt-1">
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            Choose a name that represents your brand. This name will appear in all conversations with users.
                          </p>
                        </div>
                      </div>

                      <div>
                        <Label htmlFor="chatBotTagline" className="block text-sm font-medium text-gray-900 dark:text-white">
                          Creating Your Welcome Message
                        </Label>
                        <div className="mt-1">
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            Write a friendly, brief message that greets users when they first open the chat. Make it welcoming and aligned with your brand voice.
                          </p>
                        </div>
                      </div>

                      <div>
                        <Label htmlFor="chatBotRole" className="block text-sm font-medium text-gray-900 dark:text-white">
                          Defining Bot's Purpose
                        </Label>
                        <div className="mt-1">
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            Specify your bot's role (e.g., "Customer Support", "Sales Assistant") to help set user expectations about the type of help they'll receive.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Card>
                </section>

                <section>
                  <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
                    Company Information Setup
                  </h2>
                  <Card className="dark:bg-gray-700">
                    <div className="space-y-4">
                      <div>
                        <Label htmlFor="company" className="block text-sm font-medium text-gray-900 dark:text-white">
                          Setting Company Details
                        </Label>
                        <div className="mt-1">
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            Enter your organization's name exactly as you want it to appear to users.
                          </p>
                        </div>
                      </div>

                      <div>
                        <Label htmlFor="country" className="block text-sm font-medium text-gray-900 dark:text-white">
                          Location Settings
                        </Label>
                        <div className="mt-1">
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            Select your company's country to help provide localized support experience.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Card>
                </section>
              </div>
            </Tabs.Item>

            <Tabs.Item
              title={
                <div className="flex items-center gap-2">
                  <HiColorSwatch className="h-5 w-5" />
                  <span>Appearance</span>
                </div>
              }
            >
              <section>
                <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
                  Customizing Your Chat Widget
                </h2>
                <Card className="dark:bg-gray-700">
                  <div className="space-y-4">
                    <div>
                      <Label htmlFor="themeColor" className="block text-sm font-medium text-gray-900 dark:text-white">
                        Choosing Theme Colors
                      </Label>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Enter a HEX color code that matches your website's theme. This color will be used for the chat widget's main elements.
                        </p>
                      </div>
                    </div>

                    <div>
                      <Label htmlFor="chatBotLogo" className="block text-sm font-medium text-gray-900 dark:text-white">
                        Adding Your Logo
                      </Label>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Upload your company logo to brand the chat widget. Recommended size: 512x512px, max 1MB.
                        </p>
                      </div>
                    </div>

                    <div>
                      <Label htmlFor="widgetPosition" className="block text-sm font-medium text-gray-900 dark:text-white">
                        Positioning Your Widget
                      </Label>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Choose the best position for your chat widget. Consider your website's layout and user experience:
                        </p>
                        <ul className="list-disc pl-6 mt-2 text-sm text-gray-500 dark:text-gray-400">
                          <li>Bottom Right - Most common and recommended position</li>
                          <li>Bottom Left - Good for RTL websites</li>
                          <li>Top Right - Alternative for long pages</li>
                          <li>Top Left - Special layout requirements</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Card>
              </section>
            </Tabs.Item>

            <Tabs.Item
              title={
                <div className="flex items-center gap-2">
                  <HiVolumeUp className="h-5 w-5" />
                  <span>Call Settings</span>
                </div>
              }
            >
              <section>
                <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
                  Configuring Voice Call Features
                </h2>
                <Card className="dark:bg-gray-700">
                  <div className="space-y-4">
                    <div>
                      <Label htmlFor="callFeature" className="block text-sm font-medium text-gray-900 dark:text-white">
                        Voice Call Setup
                      </Label>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Enable or disable voice calls to provide direct communication between users and your support team.
                        </p>
                      </div>
                    </div>

                    <div>
                      <Label htmlFor="queueSettings" className="block text-sm font-medium text-gray-900 dark:text-white">
                        Managing Queue Settings
                      </Label>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Customize your call queue experience with these options:
                        </p>
                        <ul className="list-disc pl-6 mt-2 text-sm text-gray-500 dark:text-gray-400">
                          <li>Select music for users waiting in queue</li>
                          <li>Choose on-hold audio for paused calls</li>
                          <li>Set maximum waiting time for queued calls</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Card>
              </section>
            </Tabs.Item>
          </Tabs>

          <div className="mt-8 p-4 bg-purple-50 dark:bg-gray-700 rounded-lg">
            <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-white">
              Best Practices
            </h3>
            <ul className="list-disc pl-6 space-y-2 text-gray-600 dark:text-gray-300">
              <li>Choose a clear, professional name for your chatbot</li>
              <li>Use your brand colors for visual consistency</li>
              <li>Keep the tagline concise and welcoming</li>
              <li>Test the chat widget on different devices and screen sizes</li>
              <li>If using call features, test audio selections before deploying</li>
            </ul>
          </div>
        </Card>
      </motion.div>
    </MainLayout>
  );
};

export default ChatWidgetDocs; 