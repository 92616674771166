import React from "react";
import { Card,  Button } from "flowbite-react";
import { HiCode } from "react-icons/hi";
import { motion } from "framer-motion";
import MainLayout from "../../layouts/MainLayout";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

const InstallationDocs: React.FC = () => {
  const [copiedSection, setCopiedSection] = React.useState<string | null>(null);

  const handleCopy = (section: string) => {
    setCopiedSection(section);
    setTimeout(() => setCopiedSection(null), 2000);
  };

  return (
    <MainLayout>
      <motion.div 
        className="px-4 pt-6 max-w mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="mb-4">
          <Link 
            to="/documentation"
            className="text-purple-600 hover:text-purple-700 dark:text-purple-400"
          >
            ← Back to Documentation
          </Link>
        </div>

        <Card className="p-6 shadow-lg dark:bg-gray-800">
          <div className="flex items-center gap-3 mb-6">
            <HiCode className="w-8 h-8 text-purple-600 dark:text-purple-400" />
            <h1 className="text-3xl font-bold text-gray-800 dark:text-white">
              Getting Started: Installation Guide
            </h1>
          </div>

          <p className="text-gray-600 dark:text-gray-300 mb-8">
            Welcome! This guide will help you install the Rupeni chat widget on your website. Follow these simple steps to get started.
          </p>

          <div className="prose dark:prose-invert max-w-none">
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Before You Begin</h2>
              <Card className="dark:bg-gray-700">
                <div className="space-y-4">
                  <div>
                    <h3 className="font-medium text-gray-800 dark:text-white">What You'll Need:</h3>
                    <ul className="list-disc pl-6 mt-2 text-gray-600 dark:text-gray-300">
                      <li>Access to edit your website's HTML (usually through your CMS or hosting platform)</li>
                      <li>A few minutes of your time</li>
                      <li>Your favorite modern web browser for testing</li>
                    </ul>
                  </div>
                </div>
              </Card>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Quick Installation Guide</h2>
              <div className="space-y-6">
                <Card className="dark:bg-gray-700">
                  <div className="space-y-4">
                    <div>
                      <h3 className="font-medium text-gray-800 dark:text-white">Step 1: Add the Chat Widget</h3>
                      <p className="text-gray-600 dark:text-gray-300 mb-4">
                        Copy this code and paste it just before the closing <code>&lt;/head&gt;</code> tag of your website:
                      </p>
                      <div className="relative">
                        <pre className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg text-sm overflow-x-auto">
                          <code className="text-purple-600 dark:text-purple-400">
                            {'<script src="https://api.rupeni.com/cdn/rupeni-min.js"></script>'}
                          </code>
                        </pre>
                        <CopyToClipboard
                          text={'<script src="https://api.rupeni.com/cdn/rupeni-min.js"></script>'}
                          onCopy={() => handleCopy("script")}
                        >
                          <Button size="xs" className="absolute top-2 right-2">
                            {copiedSection === "script" ? "Copied!" : "Copy"}
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </Card>

                <Card className="dark:bg-gray-700">
                  <div className="space-y-4">
                    <div>
                      <h3 className="font-medium text-gray-800 dark:text-white">Step 2: Check Your Installation</h3>
                      <p className="text-gray-600 dark:text-gray-300 mb-2">
                        Let's make sure everything is working:
                      </p>
                      <ol className="list-decimal pl-6 text-gray-600 dark:text-gray-300">
                        <li>Save your website changes</li>
                        <li>Refresh your website in a new tab</li>
                        <li>Look for the chat widget in the bottom-right corner</li>
                        <li>Try sending a test message</li>
                      </ol>
                    </div>
                  </div>
                </Card>

                <Card className="dark:bg-gray-700">
                  <div className="space-y-4">
                    <div>
                      <h3 className="font-medium text-gray-800 dark:text-white">Step 3: Customize Your Chat Widget</h3>
                      <p className="text-gray-600 dark:text-gray-300 mb-2">
                        Make it your own! Head to the Settings section to:
                      </p>
                      <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
                        <li>Set up your chatbot's personality</li>
                        <li>Choose your brand colors</li>
                        <li>Configure welcome messages</li>
                        <li>Adjust the widget position</li>
                      </ul>
                    </div>
                  </div>
                </Card>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Need Help?</h2>
              <Card className="dark:bg-gray-700">
                <div className="space-y-4">
                  <div>
                    <h3 className="font-medium text-gray-800 dark:text-white">Common Questions</h3>
                    <div className="space-y-3 mt-2">
                      <div>
                        <p className="text-gray-800 dark:text-white font-medium">Widget not showing up?</p>
                        <p className="text-gray-600 dark:text-gray-300">
                          • Check if the script is properly added to your HTML<br />
                          • Make sure there are no JavaScript errors in your console<br />
                          • Try clearing your browser cache
                        </p>
                      </div>
                      <div>
                        <p className="text-gray-800 dark:text-white font-medium">Widget looks different?</p>
                        <p className="text-gray-600 dark:text-gray-300">
                          • Check for CSS conflicts with your website<br />
                          • Ensure you're using the latest version of the script
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </section>
          </div>
        </Card>
      </motion.div>
    </MainLayout>
  );
};

export default InstallationDocs; 