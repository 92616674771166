import React from 'react';
import { getInitials } from '../utils/messageHelpers';

interface UserAvatarProps {
  name: string;
  size?: 'sm' | 'md' | 'lg';
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ name, size = 'md' }) => {
  const sizeClasses = {
    sm: 'w-8 h-8',
    md: 'w-12 h-12',
    lg: 'w-16 h-16'
  };

  return (
    <div className={`${sizeClasses[size]} rounded-full flex items-center justify-center bg-blue-100 text-blue-800`}>
      {getInitials(name)}
    </div>
  );
} 