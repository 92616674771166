import React, { useState, useMemo } from "react";
import { Button, Table, TextInput, Pagination, Modal, Tooltip } from "flowbite-react";
import { FaSort, FaSortUp, FaSortDown, FaEye, FaEdit, FaTrash, FaRobot, FaSearch, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DomainData } from "../../../utils/types/domains";
import { deleteDomain } from "../../../api/domain";
import { User } from "../../../utils/types/authentication";
import { motion, AnimatePresence } from "framer-motion";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface DomainsTableProps {
  domains: DomainData[] | null | undefined;
  user: User;
  setDomains: React.Dispatch<React.SetStateAction<DomainData[]>>;
}

const DomainsTable: React.FC<DomainsTableProps> = ({ domains, user, setDomains }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState<{
    key: keyof DomainData;
    direction: "asc" | "desc";
  } | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [domainToDelete, setDomainToDelete] = useState<DomainData | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteResponse, setDeleteResponse] = useState<string | null>(null);
  const navigate = useNavigate();
  const itemsPerPage = 5;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = (key: keyof DomainData) => {
    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedDomains = useMemo(() => {
    if (!domains) return [];
    const sortableDomains = [...domains];
    if (sortConfig !== null) {
      sortableDomains.sort((a, b) => {
        const aValue = a[sortConfig.key] ?? "";
        const bValue = b[sortConfig.key] ?? "";
        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableDomains;
  }, [domains, sortConfig]);

  const filteredDomains = sortedDomains.filter((domain) =>
    domain.chat_bot_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredDomains.length / itemsPerPage);
  const displayedDomains = filteredDomains.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleDeleteClick = (domain: DomainData) => {
    setDomainToDelete(domain);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (domainToDelete && domainToDelete.domain) {
      setIsDeleting(true);
      try {
        const result = await deleteDomain(domainToDelete.domain);
        setIsDeleting(false);
    
        if (result === "Domain deleted successfully") {
          setDeleteResponse("Domain deleted successfully");
    
          // Update the domains state to remove the deleted domain
          const updatedDomains = domains?.filter(
            (domain) => domain.domain !== domainToDelete.domain
          );
          setDomainToDelete(null);
          setShowModal(false);
    
          // Update the table with the new domain list
          if (updatedDomains) {
            setDomains(updatedDomains);
          }
          

          toast.success('Domain deleted successfully', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          throw new Error("Failed to delete domain");
        }
      } catch (error) {
        setIsDeleting(false);
        setDeleteResponse("An error occurred while deleting the domain.");
        
        // Show error toast
        toast.error('Failed to delete domain', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };
  
  

  const closeModal = () => {
    setShowModal(false);
    setDomainToDelete(null);
    setDeleteResponse(null);
  };

  const handleViewOrEdit = (domain: DomainData) => {
    navigate("/domains/view", { state: { domain } });
  };

  const handleTraining = (domain: DomainData) => {
    navigate("/domains/chatbot-training", { state: { domain } });
  };

  const tableVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: "easeOut",
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const rowVariants = {
    hidden: { 
      opacity: 0,
      y: 15,
    },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        ease: "easeOut"
      }
    },
    exit: { 
      opacity: 0,
      y: -10,
      transition: {
        duration: 0.2,
        ease: "easeIn"
      }
    }
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6 space-y-4 sm:space-y-0">
        <div className="w-full sm:w-auto">
          {domains && domains.length >= user.plan_number_of_custom_domains && user.plan_number_of_custom_domains !== -1 ? (
            <span className="text-red-500 font-semibold">
              You have reached the maximum number of allowed domains.
            </span>
          ) : (
            <Button onClick={() => navigate("/domains/add")} gradientDuoTone="purpleToBlue">
              <FaPlus className="mr-2" /> Add Domain
            </Button>
          )}
        </div>
        <div className="w-full sm:w-auto relative">
          <TextInput
            placeholder="Search domains"
            value={searchTerm}
            onChange={handleSearchChange}
            icon={FaSearch}
            className="w-full sm:w-64"
          />
        </div>
      </div>
      <motion.div 
        className="overflow-x-auto shadow-md rounded-lg"
        initial="hidden"
        animate="visible"
        variants={tableVariants}
        layout
        layoutRoot
      >
        <Table hoverable className="min-w-full bg-white dark:bg-gray-800">
          <Table.Head>
            {["Website", "Country", "Company", "Chat Bot Name", "Chatbot Training", "Actions"].map((header) => (
              <Table.HeadCell
                key={header}
                onClick={() => handleSort(header.toLowerCase().replace(" ", "_") as keyof DomainData)}
                className="cursor-pointer"
              >
                <div className="flex items-center">
                  {header}
                  {sortConfig?.key === header.toLowerCase().replace(" ", "_") ? (
                    sortConfig.direction === "asc" ? (
                      <FaSortUp className="ml-1" />
                    ) : (
                      <FaSortDown className="ml-1" />
                    )
                  ) : (
                    <FaSort className="ml-1" />
                  )}
                </div>
              </Table.HeadCell>
            ))}
          </Table.Head>
          <AnimatePresence mode="wait">
            <Table.Body className="divide-y">
              {displayedDomains.length === 0 ? (
                <Table.Row>
                  <Table.Cell colSpan={6} className="text-center py-4">
                    No data available at the moment.
                  </Table.Cell>
                </Table.Row>
              ) : (
                displayedDomains.map((domain) => (
                  <motion.tr
                    key={domain.domain}
                    variants={rowVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    transition={{ type: "spring", stiffness: 100, damping: 15 }}
                  >
                    <Table.Cell className="font-medium">{domain.website}</Table.Cell>
                    <Table.Cell>{domain.country}</Table.Cell>
                    <Table.Cell>{domain.company}</Table.Cell>
                    <Table.Cell>{domain.chat_bot_name}</Table.Cell>
                    <Table.Cell>
                      <Button size="xs" gradientDuoTone="cyanToBlue" onClick={() => handleTraining(domain)}>
                        <FaRobot className="mr-2" /> Train
                      </Button>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="flex space-x-2">
                        <Tooltip content="View">
                          <Button size="xs" color="info" onClick={() => handleViewOrEdit(domain)}>
                            <FaEye />
                          </Button>
                        </Tooltip>
                        <Tooltip content="Edit">
                          <Button size="xs" color="warning" onClick={() => handleViewOrEdit(domain)}>
                            <FaEdit />
                          </Button>
                        </Tooltip>
                        <Tooltip content="Delete">
                          <Button size="xs" color="failure" onClick={() => handleDeleteClick(domain)}>
                            <FaTrash />
                          </Button>
                        </Tooltip>
                      </div>
                    </Table.Cell>
                  </motion.tr>
                ))
              )}
            </Table.Body>
          </AnimatePresence>
        </Table>
      </motion.div>
      <div className="flex justify-between items-center mt-4">
        <p className="text-sm text-gray-700 dark:text-gray-300">
          Showing {Math.min(itemsPerPage, filteredDomains.length)} of {filteredDomains.length} domains
        </p>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
          showIcons
        />
      </div>

      <Modal show={showModal} onClose={closeModal}>
        <Modal.Header>Confirm Deletion</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {deleteResponse ? (
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                {deleteResponse}
              </p>
            ) : (
              <>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  Are you sure you want to delete this domain? All data since
                  the creation of the chatbot on this domain will be erased.
                </p>
                <p className="text-base font-bold text-gray-700 dark:text-gray-200">
                  {domainToDelete?.website}
                </p>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {deleteResponse ? (
            <Button onClick={closeModal}>Close</Button>
          ) : (
            <>
              <Button color="failure" onClick={confirmDelete} disabled={isDeleting}>
                {isDeleting ? "Deleting..." : "Delete"}
              </Button>
              <Button color="gray" onClick={closeModal}>Cancel</Button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ zIndex: 100000000000 }}
      />
    </div>
  );
};

export default DomainsTable;
