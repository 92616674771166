import React, { useState, useEffect, useRef, useCallback } from "react";
import { Card, Button, Spinner, Select, Label } from "flowbite-react";
import { HiChat, HiPlay, HiDotsVertical, HiPlus, HiStop } from "react-icons/hi";
import MainLayout from "../../layouts/MainLayout";
import { useAuth } from "../../utils/helpers/authWrapper";
import CustomToggle from "../../components/Toggle";
import { DomainData } from "../../utils/types/domains";
import { useNavigate } from "react-router-dom";
import CustomFileInput from "../../components/CustomFileInput";
import ringtone1 from "../../assets/sound/ringtone1.mp3";
import ringtone2 from "../../assets/sound/ringtone2.mp3";
import ringtone3 from "../../assets/sound/ringtone3.mp3";
import ringtone4 from "../../assets/sound/ringtone4.mp3";
import { updateDomain } from "../../api/domain";
import { Breadcrumb } from "../../components/Breadcrumb";
import { uploadCustomAudio } from "../../api/settings";

interface ChatWidgetForm {
  chatBotName: string;
  chatBotLogoName: string;
  chatWidgetIcon: File | null;
  chatWidgetPosition: string;
  primaryColor: string;
  isEnabled: boolean;
  enableCallOption: boolean;
  inQueueAudio: string;
  inQueueAudioFile: string;
  onHoldAudio: string;
  onHoldAudioFile: string;
  queueWaitingTime: string;
  chatBotTagline: string;
  chatBotRole: string;
  chatBotExtra: string;
  company: string;
  country: string;
}

interface PendingAudioUpload {
  file: File;
  type: "inqueue" | "onhold";
}

const RINGTONE_PATHS = {
  ringtone1: new URL(ringtone1, import.meta.url).href,
  ringtone2: new URL(ringtone2, import.meta.url).href,
  ringtone3: new URL(ringtone3, import.meta.url).href,
  ringtone4: new URL(ringtone4, import.meta.url).href,
};

function ColorInput({
  value,
  onChange,
  name,
  id,
}: Readonly<{
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  id: string;
}>) {
  const colorInputRef = React.useRef<HTMLInputElement>(null);

  const handleDropdownClick = () => {
    if (colorInputRef.current) {
      colorInputRef.current.click();
    }
  };

  return (
    <div className="flex">
      <input
        type="text"
        value={value}
        onChange={onChange}
        name={name}
        id={id}
        className="rounded-l-lg focus:ring-0 focus:border-gray-300 border border-r-1 border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 "
        placeholder="#000000"
      />
      <input
        ref={colorInputRef}
        type="color"
        value={value}
        onChange={onChange}
        name={name}
        className="h-11 hover:border-gray-300 hover:cursor-pointer p-1 w-11 border-y border-gray-300 bg-gray-50 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 "
      />
      <button
        className="flex items-center justify-center w-8 rounded-r-lg border border-l-0 border-gray-300 bg-gray-50 dark:border-gray-600 dark:bg-gray-700 cursor-pointer"
        onClick={handleDropdownClick}
      >
        <svg
          className="h-4 w-4 text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
    </div>
  );
}

const isCustomAudio = (url: string) => {
  return !Object.values(RINGTONE_PATHS).includes(url);
};

const getFileNameFromUrl = (url: string) => {
  try {
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
  } catch (error) {
    return "Custom Audio";
  }
};

const ChatWidget: React.FC = () => {
  const { domains, loading } = useAuth();
  const [selectedDomain, setSelectedDomain] = useState<string>("");
  const [form, setForm] = useState<ChatWidgetForm>({
    chatBotName: "",
    chatBotLogoName: "",
    chatWidgetIcon: null,
    chatWidgetPosition: "bottom-right",
    primaryColor: "#000000",
    isEnabled: true,
    enableCallOption: false,
    inQueueAudio: "",
    inQueueAudioFile: "",
    onHoldAudio: "",
    onHoldAudioFile: "",
    queueWaitingTime: "5",
    chatBotTagline: "",
    chatBotRole: "",
    chatBotExtra: "",
    company: "",
    country: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [chatWidgetIconUrl, setChatWidgetIconUrl] = useState<string>("");
  const navigate = useNavigate();
  const inQueueAudioFileRef = useRef<HTMLInputElement>(null);
  const onHoldAudioFileRef = useRef<HTMLInputElement>(null);
  const [audioPlayer, setAudioPlayer] = useState<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] = useState<
    "inQueueAudio" | "onHoldAudio" | null
  >(null);
  const [customAudioNames, setCustomAudioNames] = useState({
    inQueueAudio: "",
    onHoldAudio: "",
  });
  const [pendingAudioUploads, setPendingAudioUploads] = useState<PendingAudioUpload[]>([]);

  const updateFormForDomain = useCallback((domain: DomainData) => {
    setForm((prev) => ({
      ...prev,
      chatBotName: domain.chat_bot_name ?? "",
      chatBotLogoName: domain.chat_bot_logo_name ?? "",
      chatWidgetPosition:
        domain.chat_bot_position === "BR"
          ? "bottom-right"
          : domain.chat_bot_position === "BL"
          ? "bottom-left"
          : domain.chat_bot_position === "TR"
          ? "top-right"
          : "top-left",
      primaryColor: domain.chat_bot_color ?? "#000000",
      isEnabled: true,
      enableCallOption: domain.chat_bot_call_enabled ?? false,
      inQueueAudio: domain.chat_bot_inqueue_url ?? "",
      onHoldAudio: domain.chat_bot_onhold_url ?? "",
      queueWaitingTime: domain.chat_bot_onhold_duration ?? "5",
      chatBotTagline: domain.chat_bot_tagline ?? "",
      chatBotRole: domain.chat_bot_role ?? "",
      chatBotExtra: domain.chat_bot_extra ?? "",
      company: domain.company ?? "",
      country: domain.country ?? "",
    }));

    setCustomAudioNames((prev) => ({
      ...prev,
      inQueueAudio: domain.chat_bot_inqueue_url && isCustomAudio(domain.chat_bot_inqueue_url) 
        ? getFileNameFromUrl(domain.chat_bot_inqueue_url)
        : "",
      onHoldAudio: domain.chat_bot_onhold_url && isCustomAudio(domain.chat_bot_onhold_url)
        ? getFileNameFromUrl(domain.chat_bot_onhold_url)
        : "",
    }));

    if (domain.chat_bot_logo) {
      setChatWidgetIconUrl(domain.chat_bot_logo);
    }
  }, []);

  useEffect(() => {
    if (domains && domains.length > 0) {
      const firstDomain = domains[0].domain;
      if (firstDomain) {
        setSelectedDomain(firstDomain);
        updateFormForDomain(domains[0]);
      }
    }
  }, [domains, updateFormForDomain]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]:
        type === "checkbox" ? (e.target as HTMLInputElement).checked : value,
    }));
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setChatWidgetIconUrl(reader.result as string);
        setForm((prev) => ({
          ...prev,
          chatWidgetIcon: file,
          chatBotLogoName: file.name,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (!files?.length) return;

    const file = files[0];
    
    if (name === "inQueueAudioFile" || name === "onHoldAudioFile") {
      const type = name === "inQueueAudioFile" ? "inqueue" : "onhold";
      const audioType = name === "inQueueAudioFile" ? "inQueueAudio" : "onHoldAudio";

      // Store file for later upload
      setPendingAudioUploads(prev => [...prev, { file, type }]);

      // Update UI state only
      setCustomAudioNames(prev => ({
        ...prev,
        [audioType]: file.name
      }));

      // Create temporary URL for preview
      const tempUrl = URL.createObjectURL(file);
      setForm(prev => ({
        ...prev,
        [audioType]: tempUrl
      }));
    }
  };

  const removeCustomAudio = (audioType: "inQueueAudio" | "onHoldAudio") => {
    setForm(prev => ({
      ...prev,
      [audioType]: "",
      [`${audioType}File`]: null,
    }));
    
    setCustomAudioNames(prev => ({
      ...prev,
      [audioType]: "",
    }));

    // Remove from pending uploads if exists
    const uploadType = audioType === "inQueueAudio" ? "inqueue" : "onhold";
    setPendingAudioUploads(prev => 
      prev.filter(upload => upload.type !== uploadType)
    );

    if (currentlyPlayingAudio === audioType) {
      stopAudio();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // 1. First update domain
      const domainData: DomainData = {
        chat_bot_color: form.primaryColor,
        chat_bot_logo: chatWidgetIconUrl,
        chat_bot_logo_name: form.chatBotLogoName,
        chat_bot_name: form.chatBotName,
        chat_bot_extra: form.chatBotExtra,
        chat_bot_tagline: form.chatBotTagline,
        chat_bot_role: form.chatBotRole,
        company: form.company,
        country: form.country,
        chat_bot_position:
          form.chatWidgetPosition === "bottom-right"
            ? "BR"
            : form.chatWidgetPosition === "bottom-left"
            ? "BL"
            : form.chatWidgetPosition === "top-right"
            ? "TR"
            : "TL",
        chat_bot_call_enabled: form.enableCallOption,
        chat_bot_inqueue_url: form.inQueueAudio,
        chat_bot_onhold_url: form.onHoldAudio,
        chat_bot_onhold_duration: form.queueWaitingTime,
        domain: selectedDomain,
      };

      const result = await updateDomain(domainData);

      if (result === "Success") {
        // 2. Then handle pending audio uploads
        if (pendingAudioUploads.length > 0) {
          await Promise.all(
            pendingAudioUploads.map(async ({ file, type }) => {
              if (!domains?.[0]?.id) return;
              
              try {
                const result = await uploadCustomAudio(file, domains[0].id, type);
                // Update form with actual URL from server
                setForm(prev => ({
                  ...prev,
                  [type === "inqueue" ? "inQueueAudio" : "onHoldAudio"]: result.url
                }));
              } catch (error) {
                console.error(`Failed to upload ${type} audio:`, error);
              }
            })
          );
        }

        // Clear pending uploads
        setPendingAudioUploads([]);
        
        console.log("Domain and audio files updated successfully");
      } else {
        console.error("Failed to update domain");
      }
    } catch (error) {
      console.error("Error updating domain:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleToggleChange = (checked: boolean, name: string) => {
    setForm((prev) => ({ ...prev, [name]: checked }));
  };

  const handleInQueueUploadClick = () => {
    inQueueAudioFileRef.current?.click();
  };

  const handleOnHoldUploadClick = () => {
    onHoldAudioFileRef.current?.click();
  };

  const handleDomainChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newDomain = e.target.value;
    setSelectedDomain(newDomain);
    const selectedDomain = domains.find((d) => d.domain === newDomain);
    if (selectedDomain) {
      updateFormForDomain(selectedDomain);
    }
  };

  const playAudio = (
    audioSrc: string,
    audioType: "inQueueAudio" | "onHoldAudio"
  ) => {
    if (audioPlayer) {
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
    }
    const newAudio = new Audio(audioSrc);
    newAudio.play();
    newAudio.onended = () => {
      setIsPlaying(false);
      setCurrentlyPlayingAudio(null);
    };
    setAudioPlayer(newAudio);
    setIsPlaying(true);
    setCurrentlyPlayingAudio(audioType);
  };

  const stopAudio = () => {
    if (audioPlayer) {
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
    }
    setIsPlaying(false);
    setCurrentlyPlayingAudio(null);
  };

  const handleAudioSelection = (
    e: React.ChangeEvent<HTMLSelectElement>,
    audioType: "inQueueAudio" | "onHoldAudio"
  ) => {
    const selectedValue = e.target.value;

    setForm((prev) => ({
      ...prev,
      [audioType]: selectedValue,
    }));

    if (selectedValue) {
      playAudio(selectedValue, audioType);
    } else {
      stopAudio();
    }
  };

  const handlePlayStop = (
    audioSrc: string,
    audioType: "inQueueAudio" | "onHoldAudio"
  ) => {
    if (isPlaying && currentlyPlayingAudio === audioType) {
      stopAudio();
    } else {
      playAudio(audioSrc, audioType);
    }
  };

  // Clean up temporary URLs on unmount
  useEffect(() => {
    const { inQueueAudio, onHoldAudio } = form;
    
    return () => {
      // Revoke any temporary URLs we created
      if (inQueueAudio?.startsWith('blob:')) {
        URL.revokeObjectURL(inQueueAudio);
      }
      if (onHoldAudio?.startsWith('blob:')) {
        URL.revokeObjectURL(onHoldAudio);
      }
    };
  }, [form]);

  if (loading) {
    return (
      <MainLayout>
        <div className="flex justify-center items-center h-screen">
          <Spinner size="xl" />
        </div>
      </MainLayout>
    );
  }

  const noDomains = !domains || domains.length === 0;

  return (
    <MainLayout>
      <div className="px-4 py-8">
        <Breadcrumb
          items={[
            { label: "Chat Widget Settings", path: "/settings/chat-widget" },
          ]}
        />
        <div className="relative">
          <Card className="p-0">
            <div className="flex flex-col md:flex-row gap-8">
              <div className="w-full md:w-3/5">
                <div className="flex items-center gap-4 mb-3">
                  <HiChat className="w-8 h-8 text-gray-800 dark:text-gray-400" />
                  <h1 className="text-2xl font-bold dark:text-white">
                    Chat Widget Settings
                  </h1>
                </div>

                {/* Domain Selection Dropdown */}
                {!noDomains && (
                  <div className="mb-4">
                    <Label htmlFor="domainSelect" className="mb-2 block">
                      Select Domain
                    </Label>
                    <Select
                      id="domainSelect"
                      value={selectedDomain}
                      onChange={handleDomainChange}
                    >
                      {domains?.map((domain) => (
                        <option key={domain.domain} value={domain.domain}>
                          {domain.domain}
                        </option>
                      ))}
                    </Select>
                  </div>
                )}

                <form onSubmit={handleSubmit} className="space-y-3">
                  <div>
                    <Label htmlFor="chatBotLogoUrl" className="mb-2 block">
                      Chat Widget Icon
                    </Label>
                    <CustomFileInput
                      id="chatBotLogoUrl"
                      accept=".jpeg,.jpg,.webp,.gif,.bmp,.tiff,.svg"
                      onChange={handleLogoChange}
                      fileName={form.chatBotLogoName}
                    />
                  </div>
                  <div>
                    <Label htmlFor="chatWidgetPosition" className="mb-2 block">
                      Chat Widget Position
                    </Label>
                    <Select
                      id="chatWidgetPosition"
                      name="chatWidgetPosition"
                      value={form.chatWidgetPosition}
                      onChange={handleInputChange}
                    >
                      <option value="bottom-right">Bottom Right</option>
                      <option value="bottom-left">Bottom Left</option>
                      <option value="top-right">Top Right</option>
                      <option value="top-left">Top Left</option>
                    </Select>
                  </div>
                  <div>
                    <Label htmlFor="primaryColor" className="mb-2 block">
                      Primary Widget Color
                    </Label>
                    <ColorInput
                      id="primaryColor"
                      name="primaryColor"
                      value={form.primaryColor}
                      onChange={handleInputChange}
                    />
                  </div>

                  <h1 className="text-lg dark:text-white font-bold">
                    Advanced Options
                  </h1>
                  <div>
                    <div className="flex items-center justify-between">
                      <Label
                        htmlFor="isEnabled"
                        className="text-sm text-gray-700 dark:text-gray-300"
                      >
                        Enable Call Option
                      </Label>
                      <CustomToggle
                        id="isEnabled"
                        label=""
                        checked={form.enableCallOption}
                        onChange={(checked) =>
                          handleToggleChange(checked, "enableCallOption")
                        }
                      />
                    </div>
                    <p className="text-sm text-gray-500">
                      Give your customer an option make a call directly from the
                      chat widget and speak to your customer support
                      representative.
                    </p>
                  </div>
                  <div className="space-y-4">
                    <div>
                      <Label htmlFor="inQueueAudio" className="mb-2 block">
                        In-Queue Audio
                      </Label>
                      <div className="flex items-center gap-2">
                        <Select
                          id="inQueueAudio"
                          name="inQueueAudio"
                          value={form.inQueueAudio}
                          onChange={(e) =>
                            handleAudioSelection(e, "inQueueAudio")
                          }
                          className="w-2/5"
                        >
                          <option value="">Select In-Queue Audio</option>
                          <option value={RINGTONE_PATHS.ringtone1}>
                            Ringtone 1
                          </option>
                          <option value={RINGTONE_PATHS.ringtone2}>
                            Ringtone 2
                          </option>
                          {form.inQueueAudio && isCustomAudio(form.inQueueAudio) && (
                            <option value={form.inQueueAudio}>
                              {customAudioNames.inQueueAudio || "Custom Audio"}
                            </option>
                          )}
                        </Select>
                        <button
                          type="button"
                          onClick={() =>
                            form.inQueueAudio &&
                            handlePlayStop(form.inQueueAudio, "inQueueAudio")
                          }
                          className="focus:outline-none focus:ring-0 focus:ring-transparent focus:bg-transparent focus:border-transparent"
                        >
                          {isPlaying &&
                          currentlyPlayingAudio === "inQueueAudio" ? (
                            <HiStop className="w-8 h-8 text-[#006AC1]" />
                          ) : (
                            <HiPlay className="w-8 h-8 text-[#006AC1]" />
                          )}
                        </button>
                      </div>
                      <div className="mt-2 flex items-center">
                        <input
                          type="file"
                          ref={inQueueAudioFileRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                          accept=".mp3"
                          name="inQueueAudioFile"
                          id="inQueueAudioFile"
                        />
                        <button
                          className="text-sm text-blue-500 cursor-pointer hover:text-blue-700"
                          onClick={handleInQueueUploadClick}
                        >
                          Upload Custom Audio (.mp3)
                        </button>
                        {customAudioNames.inQueueAudio && (
                          <div className="ml-2 flex items-center bg-gray-100 rounded-full px-3 py-1">
                            <span className="text-sm">
                              {customAudioNames.inQueueAudio}
                            </span>
                            <button
                              onClick={() => removeCustomAudio("inQueueAudio")}
                              className="ml-2 text-red-500 hover:text-red-700"
                            >
                              ×
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <Label htmlFor="onHoldAudio" className="mb-2 block">
                        On-Hold Audio
                      </Label>
                      <div className="flex items-center gap-2">
                        <Select
                          id="onHoldAudio"
                          name="onHoldAudio"
                          value={form.onHoldAudio}
                          onChange={(e) =>
                            handleAudioSelection(e, "onHoldAudio")
                          }
                          className="w-2/5"
                        >
                          <option value="">Select On-Hold Audio</option>
                          <option value={RINGTONE_PATHS.ringtone3}>
                            Ringtone 3
                          </option>
                          <option value={RINGTONE_PATHS.ringtone4}>
                            Ringtone 4
                          </option>
                          {form.onHoldAudio && isCustomAudio(form.onHoldAudio) && (
                            <option value={form.onHoldAudio}>
                              {customAudioNames.onHoldAudio || "Custom Audio"}
                            </option>
                          )}
                        </Select>
                        <button
                          type="button"
                          onClick={() =>
                            form.onHoldAudio &&
                            handlePlayStop(form.onHoldAudio, "onHoldAudio")
                          }
                          className="focus:outline-none focus:ring-0 focus:ring-transparent focus:bg-transparent focus:border-transparent"
                        >
                          {isPlaying &&
                          currentlyPlayingAudio === "onHoldAudio" ? (
                            <HiStop className="w-8 h-8 text-[#006AC1]" />
                          ) : (
                            <HiPlay className="w-8 h-8 text-[#006AC1]" />
                          )}
                        </button>
                      </div>
                      <div className="mt-2 flex items-center">
                        <input
                          type="file"
                          ref={onHoldAudioFileRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                          accept=".mp3"
                          name="onHoldAudioFile"
                          id="onHoldAudioFile"
                        />
                        <button
                          className="text-sm text-blue-500 cursor-pointer hover:text-blue-700"
                          onClick={handleOnHoldUploadClick}
                        >
                          Upload Custom Audio (.mp3)
                        </button>
                        {customAudioNames.onHoldAudio && (
                          <div className="ml-2 flex items-center bg-gray-100 rounded-full px-3 py-1">
                            <span className="text-sm">
                              {customAudioNames.onHoldAudio}
                            </span>
                            <button
                              onClick={() => removeCustomAudio("onHoldAudio")}
                              className="ml-2 text-red-500 hover:text-red-700"
                            >
                              ×
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <Label htmlFor="queueWaitingTime" className="mb-2 block">
                        Queue Waiting Time
                      </Label>
                      <Select
                        id="queueWaitingTime"
                        name="queueWaitingTime"
                        value={form.queueWaitingTime}
                        onChange={handleInputChange}
                      >
                        <option value="5">5 minutes</option>
                        <option value="10">10 minutes</option>
                        <option value="15">15 minutes</option>
                        <option value="20">20 minutes</option>
                      </Select>
                      <p className="text-sm text-gray-500">
                        Choose how long you'd like your customer to wait in
                        queue.
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              <div className="w-full md:w-2/5 border-l-2 border-gray-300 flex justify-center items-center">
                <div className="bg-gray-100 w-[400px] dark:bg-gray-700 rounded-[50px] h-full flex flex-col">
                  <div
                    className="text-white px-5 py-4 flex justify-between items-center rounded-[50px] mx-3 mt-[12px]"
                    style={{ background: form.primaryColor }}
                  >
                    <img
                      src={
                        chatWidgetIconUrl ||
                        "https://placehold.co/900x900?text=?"
                      }
                      alt="chat widget icon"
                      className="w-12 h-12 rounded-full mr-3 border-1 border-white"
                    />
                    <div className="flex flex-col">
                      <p className="text-lg font-bold">{form.chatBotName}</p>
                      <div className="flex items-center gap-2">
                        <div className="bg-green-400 w-3 h-3 rounded-full mr-1"></div>
                        <p className="text-sm">Active</p>
                      </div>
                    </div>
                    <div className="ml-auto">
                      <HiDotsVertical className="w-7 h-7" />
                    </div>
                  </div>

                  <div className="flex-1 p-3 overflow-y-auto flex flex-col-reverse relative">
                    <div
                      style={{ background: form.primaryColor }}
                      className="text-white p-3 m-1 rounded-xl self-end opacity-100 translate-y-0 max-w-[70%] relative"
                    >
                      <p>Hello, I need assistance</p>
                    
                    </div>

                    <div className="bg-white text-black p-3 m-1 rounded-xl self-start opacity-100 translate-y-0 max-w-[70%] ">
                      <p>Bula, how can I assist you today?</p>
                      <div className="flex flex-col items-center justify-center gap-3 my-3">
                        <button
                          style={{ background: form.primaryColor }}
                          className="text-white border-none rounded-[20px] py-2 px-4 font-bold cursor-pointer hover:opacity-90 transition-opacity"
                         
                        >
                          I need help with ...
                        </button>
                      </div>
                      <div className="flex flex-col items-center justify-center gap-3 my-3">
                        <button
                          style={{ background: form.primaryColor }}
                          className="text-white border-none rounded-[20px] py-2 px-4 font-bold cursor-pointer hover:opacity-90 transition-opacity"
                       
                        >
                          I want to make a complaint
                        </button>
                      </div>
                      <div className="flex flex-col items-center justify-center gap-3 my-3">
                        <button
                          style={{ background: form.primaryColor }}
                          className="text-white border-none rounded-[20px] py-2 px-4 font-bold cursor-pointer hover:opacity-90 transition-opacity"
                         
                        >
                          I can't find what I'm looking for
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ background: form.primaryColor }}
                    className="p-5 flex flex-col rounded-br-[50px] rounded-bl-[50px] "
                  >
                    <div className="flex bg-white rounded-xl overflow-hidden">
                      <input
                        placeholder="Type a message..."
                        className="flex-1 border-none p-3 rounded-xl outline-none "
                      ></input>
                      <button className="text-3xl cursor-pointer mx-1 bg-transparent border-none flex items-center justify-center">
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill={form.primaryColor}
                        >
                          <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"></path>
                        </svg>
                      </button>
                    </div>
                    <div className="flex text-sm text-white justify-center m-1">
                      <p>Powered by</p>
                      <a
                        href="https://rupeni.com/"
                        target="_blank"
                        className="text-white font-bold ml-1 "
                      >
                        Rupeni
                      </a>
                    </div>
                  </div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </Card>

          {noDomains && (
            <div className="absolute inset-0 bg-gray-900 bg-opacity-50 rounded-xl flex items-center justify-center backdrop-blur-sm">
              <div className="text-center text-white p-6 rounded-lg bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center">
                <HiPlus className="h-12 w-12 mb-4" />
                <h2 className="text-2xl font-bold mb-2">No Domains Found</h2>
                <p className="mb-4">
                  Add a new domain to get started with chat widget settings.
                </p>
                <Button
                  gradientDuoTone="purpleToBlue"
                  onClick={() => {
                    navigate("/domains/add");
                  }}
                  className="mx-auto"
                >
                  Add New Domain
                </Button>
              </div>
            </div>
          )}
        </div>

        {!noDomains && (
          <div className="mt-6">
            <Button
              type="button"
              disabled={isSubmitting}
              className="rounded-md bg-[#006AC1] hover:bg-[#006AC1]"
              onClick={handleSubmit}
            >
              {isSubmitting ? <Spinner /> : "Save Changes"}
            </Button>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default ChatWidget;
