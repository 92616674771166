import React, { useRef, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import parse, {
  domToReact,
  HTMLReactParserOptions,
  DOMNode,
} from "html-react-parser";
import { motion } from "framer-motion";
import "./styles.css";

interface Plan {
  id: number;
  name: string;
  price: number;
  inclusions: string;
  created_at: string;
}

interface PlansViewProps {
  plans: Plan[];
  selectedPlanId?: number;
}

const modifyInclusions = (inclusions: string, index: number) => {
  if (index === 0) {
    return inclusions.replace(/30 days free trial/, "30 days Money Back Guarantee");
  } else if (index === 1) {
    return inclusions.replace(
      /<li>5 domains<\/li>/,
      "<li>3 domains</li><li>Unlimited Chats</li>"
    );
  }
  return inclusions;
};

const renderInclusions = (inclusions: string, index: number) => {
  const modifiedInclusions = modifyInclusions(inclusions, index);

  const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      if (domNode.type === "tag" && domNode.name === "li") {
        return (
          <li className="flex items-center mb-2">
            <FaCheck className="text-green-400 dark:text-green-500 mr-2 flex-shrink-0" />
            <span className="text-gray-800 dark:text-gray-200">{domToReact(domNode.children as DOMNode[])}</span>
          </li>
        );
      }
    },
  };

  return parse(modifiedInclusions, options);
};

const PlansView: React.FC<PlansViewProps> = ({ plans, selectedPlanId }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const sortedPlans = [...plans].sort((a, b) => a.id - b.id);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const rect = container.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      if (rect.bottom > viewportHeight) {
        container.style.top = 'auto';
        container.style.bottom = '100%';
        container.style.marginTop = '0';
        container.style.marginBottom = '0.5rem';
      }

      if (rect.right > viewportWidth) {
        container.style.right = '0';
        container.style.left = 'auto';
      }
    }
  }, []);

  const getPriceDisplay = (index: number, plan: Plan): string => {
    if (index === 2) {
      return "Custom";
    } else {
      return `$${plan.price}`;
    }
  };

  return (
    <motion.div 
      ref={containerRef}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.2 }}
      className="absolute top-full mt-2 w-full min-w-[300px] max-w-md bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4 z-50 right-0 border border-gray-200 dark:border-gray-700 max-h-[80vh] overflow-y-auto custom-scrollbar"
    >
      <h3 className="text-lg font-semibold mb-4 text-center text-purple-600 dark:text-purple-400">
        Available Plans
      </h3>
      <div className="space-y-4">
        {sortedPlans.map((plan, index) => (
          <motion.div
            key={plan.id}
            whileHover={{ scale: 1.02 }}
            className={`p-4 border rounded-lg transition-all duration-200 ${
              plan.id === selectedPlanId
                ? "border-purple-500 bg-purple-50 dark:bg-gray-700"
                : "border-gray-200 dark:border-gray-600 dark:bg-gray-700 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-700"
            }`}
          >
            <h4 className="text-md font-medium text-purple-700 dark:text-purple-300 mb-1">{plan.name}</h4>
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-3">
              Price:{" "}
              <span className="font-semibold text-gray-800 dark:text-gray-200">
                {getPriceDisplay(index, plan)}
              </span>
            </p>
            <ul className="text-sm space-y-1">
              {renderInclusions(plan.inclusions, index)}
            </ul>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default PlansView;
