import React from "react";
import { Card } from "flowbite-react";
import {
  HiCode,
  HiCog,
  HiGlobe,
  HiDatabase,
  HiUserGroup,
} from "react-icons/hi";
import { motion } from "framer-motion";
import MainLayout from "../../layouts/MainLayout";
import { Link } from "react-router-dom";

const Documentation: React.FC = () => {
  const sections = [
    {
      id: "installation",
      title: "Installation Guide",
      description: "Learn how to integrate Rupeni AI into your website with our step-by-step installation guide.",
      icon: <HiCode className="w-6 h-6" />,
    },
    {
      id: "chat-widget",
      title: "Chat Widget Configuration",
      description: "Customize your chat widget's appearance and behavior to create the perfect user experience.",
      icon: <HiCog className="w-6 h-6" />,
    },
    {
      id: "training",
      title: "AI Training",
      description: "Train your AI chatbot with custom data to provide accurate and relevant responses.",
      icon: <HiDatabase className="w-6 h-6" />,
    },
    {
      id: "domains",
      title: "Domain Management",
      description: "Manage and configure multiple domains for your Rupeni AI implementation.",
      icon: <HiGlobe className="w-6 h-6" />,
    },
    {
      id: "roles",
      title: "User Roles & Permissions",
      description: "Set up and manage team access with role-based permissions.",
      icon: <HiUserGroup className="w-6 h-6" />,
    },
    {
      id: "preferences",
      title: "Preferences",
      description: "Configure your notification settings, call preferences, and language options.",
      icon: <HiCog className="w-6 h-6" />,
    },
  ];

  return (
    <MainLayout>
      <motion.div 
        className="px-4 pt-6 max-w mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Card className="p-6 shadow-lg dark:bg-gray-800">
          <motion.div 
            className="mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <h1 className="text-3xl font-bold mb-4 text-purple-600 dark:text-purple-400">
              How can we help?
            </h1>
            <p className="text-gray-600 dark:text-gray-300">
              Select a topic below to learn more about our features and get step-by-step guidance.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {sections.map((section, index) => (
              <motion.div
                key={section.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <Link to={`/documentation/${section.id}`}>
                  <Card className="h-full hover:shadow-lg transition-shadow duration-300 dark:bg-gray-700">
                    <div className="flex items-center gap-3 mb-4 text-purple-600 dark:text-purple-400">
                      {section.icon}
                      <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
                        {section.title}
                      </h2>
                    </div>
                    <p className="text-gray-600 dark:text-gray-300">
                      {section.description}
                    </p>
                  </Card>
                </Link>
              </motion.div>
            ))}
          </div>
        </Card>
      </motion.div>
    </MainLayout>
  );
};

export default Documentation;