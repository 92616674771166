import { useAuth } from "../helpers/authWrapper";
import { Permission } from "../types/authentication";
import {
  hasPermission,
  hasAllPermissions,
  hasAnyPermission,
} from "../permissions/config";

export function usePermissions() {
  const { user } = useAuth();

  if (!user) {
    return {
      can: () => false,
      canAll: () => false,
      canAny: () => false,
    };
  }

  return {
    can: (permission: Permission) => hasPermission(user.role, permission),

    canAll: (permissions: Permission[]) =>
      hasAllPermissions(user.role, permissions),

    canAny: (permissions: Permission[]) =>
      hasAnyPermission(user.role, permissions),
  };
}

export type { Permission };
