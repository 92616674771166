import React from 'react';
import { Link } from 'react-router-dom';
import { HiChevronRight } from 'react-icons/hi';

interface BreadcrumbItem {
  label: string;
  path: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <nav className="flex mb-4" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        <li className="inline-flex items-center">
          <Link 
            to="/settings"
            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
          >
            Settings
          </Link>
        </li>
        {items.map((item, index) => (
          <li key={item.path}>
            <div className="flex items-center">
              <HiChevronRight className="w-5 h-5 text-gray-400" />
              <Link
                to={item.path}
                className={`ml-1 text-sm font-medium md:ml-2 
                  ${index === items.length - 1 
                    ? 'text-blue-600 dark:text-blue-500' 
                    : 'text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white'
                  }`}
              >
                {item.label}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}; 