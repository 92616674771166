import React from "react";
import { UserAvatar } from "./UserAvatar";
import DOMPurify from "dompurify";

interface ChatMessageProps {
  message: string;
  sender: "user" | "assistant" | "system" | "human_assistant";
  initials: string;
  agentName?: string;
}

export const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  sender,
  initials,
  agentName,
}) => {
  // Clean the message by removing the rupeni button container and any resulting empty lines
  const cleanMessage = message
    .replace(/<div class='rupeni-option-btn-container'>.*?<\/div>/gs, '')
    .replace(/\n\s*\n/g, '\n') // Remove multiple consecutive newlines
    .trim();

  // Create a sanitized version of the HTML
  const createSanitizedHTML = (content: string) => {
    const sanitizedHTML = DOMPurify.sanitize(content, {
      ALLOWED_TAGS: ["p", "br", "ul", "li", "a", "div", "button"],
      ALLOWED_ATTR: ["href", "target", "class"],
    });
    return { __html: sanitizedHTML };
  };

  const getMessageStyles = (isUser: boolean, isHumanAssistant: boolean) => {
    if (isUser) {
      return "bg-blue-500 text-white";
    }
    if (isHumanAssistant) {
      return "bg-green-500 text-white";
    }
    return "bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100";
  };

  if (sender === "system") {
    return (
      <div className="flex justify-center my-2">
        <span className="text-xs text-gray-500 bg-gray-100 dark:bg-gray-700 dark:text-gray-400 px-3 py-1 rounded-full">
          {cleanMessage}
        </span>
      </div>
    );
  }

  const isUser = sender === "user";
  const isHumanAssistant = sender === "human_assistant";

  return (
    <div
      className={`flex ${
        isUser ? "justify-start" : "justify-end"
      } mb-4 items-start max-w-full`}
    >
      {isUser && (
        <div className="flex-shrink-0 mr-2">
          <UserAvatar name={initials} size="sm" />
        </div>
      )}
      <div
        className={`max-w-[70%] px-4 py-2 rounded-lg ${getMessageStyles(
          isUser,
          isHumanAssistant
        )} break-words overflow-hidden [&_ul]:list-disc [&_ul]:pl-4 [&_ul]:my-2 [&_a]:underline [&_a]:text-blue-200 hover:[&_a]:text-blue-100`}
      >
        <div
          className="whitespace-pre-wrap break-words overflow-wrap-anywhere"
          dangerouslySetInnerHTML={createSanitizedHTML(cleanMessage)}
        />
      </div>
      {!isUser && (
        <div className="flex-shrink-0 ml-2">
          <UserAvatar 
            name={isHumanAssistant ? (agentName || "Support") : "AI"} 
            size="sm" 
          />
        </div>
      )}
    </div>
  );
};
