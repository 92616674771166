import React from "react";
import { Card, ListGroup, Alert, Table } from "flowbite-react";
import { 
  HiUserGroup,
  HiShieldCheck,
  HiKey,
  HiExclamation,
  HiPlus,
} from "react-icons/hi";
import { motion } from "framer-motion";
import MainLayout from "../../layouts/MainLayout";
import { Link } from "react-router-dom";

const RolesDocs: React.FC = () => {
  const roleTypes = [
    {
      role: "Owner",
      description: "Full system access with admin management",
      permissions: [
        "Full system access",
        "Manage all users (including admins)",
        "Manage domains",
        "Access analytics",
        "View and manage chats",
        "Chat takeover"
      ]
    },
    {
      role: "Admin",
      description: "Full system access without admin management",
      permissions: [
        "Full system access",
        "Manage agents and viewers",
        "Manage domains",
        "Access analytics",
        "View and manage chats",
        "Chat takeover"
      ]
    },
    {
      role: "Agent",
      description: "Chat management and analytics access",
      permissions: [
        "View dashboard analytics",
        "View conversations",
        "Chat takeover",
      ]
    },
    {
      role: "Viewer",
      description: "Basic viewing access",
      permissions: [
        "View dashboard analytics",
        "View conversations",
      ]
    }
  ];

  return (
    <MainLayout>
      <motion.div 
        className="px-4 pt-6 max-w mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="mb-4">
          <Link 
            to="/documentation"
            className="text-purple-600 hover:text-purple-700 dark:text-purple-400"
          >
            ← Back to Documentation
          </Link>
        </div>

        <Card className="p-6 shadow-lg dark:bg-gray-800">
          <div className="flex items-center gap-3 mb-6">
            <HiUserGroup className="w-8 h-8 text-purple-600 dark:text-purple-400" />
            <h1 className="text-3xl font-bold text-gray-800 dark:text-white">
              Understanding User Roles
            </h1>
          </div>

          <p className="text-gray-600 dark:text-gray-300 mb-8">
            Welcome to our user roles guide! We'll help you understand how different roles work in your Rupeni setup, making it easy to manage your team and their access levels. Let's explore what each role can do!
          </p>

          <div className="prose dark:prose-invert max-w-none space-y-12">
            <section>
              <h2 className="text-2xl font-semibold mb-6 flex items-center gap-2 text-gray-800 dark:text-white">
                <HiKey className="w-6 h-6" />
                Available Roles & What They Can Do
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Here's a clear breakdown of each role and their capabilities. Choose the right role for each team member based on their responsibilities:
              </p>
              <div className="overflow-x-auto">
                <Table>
                  <Table.Head>
                    <Table.HeadCell>Role</Table.HeadCell>
                    <Table.HeadCell>Description</Table.HeadCell>
                    <Table.HeadCell>Permissions</Table.HeadCell>
                  </Table.Head>
                  <Table.Body>
                    {roleTypes.map((role) => (
                      <Table.Row key={role.role} className="dark:border-gray-700">
                        <Table.Cell className="font-medium">
                          {role.role}
                        </Table.Cell>
                        <Table.Cell>
                          {role.description}
                        </Table.Cell>
                        <Table.Cell>
                          <ul className="list-disc pl-4">
                            {role.permissions.map((permission) => (
                              <li key={permission}>{permission}</li>
                            ))}
                          </ul>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-6 flex items-center gap-2 text-gray-800 dark:text-white">
                <HiShieldCheck className="w-6 h-6" />
                Who Can Manage Who?
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Let's clarify who has the power to manage other users. This helps maintain proper security and organization in your team:
              </p>
              <Card className="dark:bg-gray-700">
                <div className="space-y-4">
                  <ListGroup>
                    <ListGroup.Item className="bg-transparent">
                      <span className="font-medium">Owner:</span> The captain of the ship! Can manage everyone, including admins
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent">
                      <span className="font-medium">Admin:</span> Your trusted lieutenants who can manage agents and viewers
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent">
                      <span className="font-medium">Agent:</span> Focused on chat management - no user management needed
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent">
                      <span className="font-medium">Viewer:</span> Can observe but not modify - perfect for analytics tracking
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Card>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-6 flex items-center gap-2 text-gray-800 dark:text-white">
                <HiPlus className="w-6 h-6" />
                Growing Your Team
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Ready to add new team members? Here's your simple guide to bringing new users onboard:
              </p>
              <Card className="dark:bg-gray-700">
                <div className="space-y-6">
                  <h3 className="font-medium text-gray-800 dark:text-white">Three Easy Steps to Add Users:</h3>
                  <ol className="space-y-6">
                    <li className="flex gap-4">
                      <span className="flex-shrink-0 w-8 h-8 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400 font-semibold">
                        1
                      </span>
                      <div>
                        <h4 className="font-medium text-gray-800 dark:text-white">Send Your Invites</h4>
                        <p className="text-gray-600 dark:text-gray-300">
                          Head to the Role Permissions page, click "Invite Agents", and add up to 10 email addresses at once (just separate them with commas)
                        </p>
                      </div>
                    </li>
                    <li className="flex gap-4">
                      <span className="flex-shrink-0 w-8 h-8 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400 font-semibold">
                        2
                      </span>
                      <div>
                        <h4 className="font-medium text-gray-800 dark:text-white">They'll Get an Email</h4>
                        <p className="text-gray-600 dark:text-gray-300">
                          Your team members will receive a friendly email with everything they need to get started
                        </p>
                      </div>
                    </li>
                    <li className="flex gap-4">
                      <span className="flex-shrink-0 w-8 h-8 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400 font-semibold">
                        3
                      </span>
                      <div>
                        <h4 className="font-medium text-gray-800 dark:text-white">Quick Setup</h4>
                        <p className="text-gray-600 dark:text-gray-300">
                          They'll complete a simple setup process:
                        </p>
                        <ul className="list-disc pl-4 mt-2 text-gray-600 dark:text-gray-300">
                          <li>Add their name</li>
                          <li>Create a secure password</li>
                          <li>Confirm the password</li>
                          <li>And they're ready to go!</li>
                        </ul>
                      </div>
                    </li>
                  </ol>
                </div>
              </Card>

              <Alert color="info" icon={HiExclamation} className="mt-6">
                Quick tip: Only Owners and Admins can send invites, and they'll set the appropriate role for new team members.
              </Alert>
            </section>

            <Alert color="info" icon={HiExclamation}>
              Remember: Domain management and system settings are reserved for Owners and Admins. Agents and Viewers focus on the dashboard and chat features.
            </Alert>
          </div>
        </Card>
      </motion.div>
    </MainLayout>
  );
};

export default RolesDocs; 