import type { PropsWithChildren } from "react";
import {
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import isBrowser from "../../utils/helpers/is-browser";
import isSmallScreen from "../../utils/helpers/is-small-screen";

interface SidebarContextProps {
  isOpenOnSmallScreens: boolean;
  isPageWithSidebar: boolean;
  setOpenOnSmallScreens: (isOpen: boolean) => void;
}

export const SidebarContext = createContext<SidebarContextProps | undefined>(
  undefined
);

export function SidebarProvider({ children }: Readonly<PropsWithChildren>): JSX.Element {
  const location = isBrowser() ? window.location.pathname : "/";
  const [isOpen, setIsOpen] = useState(
    isBrowser()
      ? window.localStorage.getItem("isSidebarOpen") === "true"
      : false
  );

  useEffect(() => {
    if (isBrowser()) {
      window.localStorage.setItem("isSidebarOpen", isOpen.toString());
    }
  }, [isOpen]);

  useEffect(() => {
    if (isSmallScreen()) {
      setIsOpen(false);
    }
  }, [location]);

  // const handleMobileTapInsideMain = useCallback((event: MouseEvent) => {
  //   const main = document.querySelector("main");
  //   const isClickInsideMain = main?.contains(event.target as Node);

  //   if ( isClickInsideMain) {
  //     setOpen(false);
  //   }
  // }, []);


  return useMemo(() => (
      <SidebarContext.Provider
        value={{
          isOpenOnSmallScreens: isOpen,
          isPageWithSidebar: true,
          setOpenOnSmallScreens: setIsOpen,
        }}
      >
        {children}
      </SidebarContext.Provider>
    ), [isOpen, setIsOpen, children]);
  }


