import React from "react"
import { Link } from "react-router-dom"
import MainLayout from "../../layouts/MainLayout"
import { Card } from "flowbite-react"
import { HiOfficeBuilding, HiChat, HiCog, HiUserGroup, HiLockClosed } from "react-icons/hi"
import { usePermissions } from '../../utils/hooks/usePermissions';
import { Permission } from '../../utils/hooks/usePermissions';
import { IconType } from 'react-icons';

interface SettingsItem {
  name: string;
  icon: IconType;
  path: string;
  permission: Permission;
}

const Settings: React.FC = () => {
  const { can } = usePermissions();

  const settingsItems: SettingsItem[] = [
    { 
      name: "Company Details", 
      icon: HiOfficeBuilding, 
      path: "company-details",
      permission: 'manage_company_details'
    },
    { 
      name: "Chat Widget Setting", 
      icon: HiChat, 
      path: "chat-widget",
      permission: 'manage_chat_widget'
    },
    { 
      name: "Preference", 
      icon: HiCog, 
      path: "preference",
      permission: 'manage_preferences'
    },
    { 
      name: "Role Permission", 
      icon: HiUserGroup, 
      path: "role-permission",
      permission: 'manage_roles'
    },
    { 
      name: "Change Password", 
      icon: HiLockClosed, 
      path: "change-password",
      permission: 'change_password'
    },
  ]

  const authorizedItems = settingsItems.filter(item => can(item.permission));

  return (
    <MainLayout>
      <div className="px-4 py-8">
        <Card>
          <h1 className="text-2xl font-bold mb-6 dark:text-white">General Settings</h1>
          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            {authorizedItems.map((item) => (
              <li key={item.name}>
                <Link
                  to={`/settings/${item.path}`}
                  className="flex items-center gap-4 py-3 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-150 ease-in-out"
                >
                  <item.icon className="w-5 h-5 text-gray-800 dark:text-gray-400" />
                  <span className="text-gray-800 dark:text-gray-300">{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </Card>
      </div>
    </MainLayout>
  )
}

export default Settings