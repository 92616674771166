//@ts-expect-error-next-line
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../utils/helpers/authWrapper';
import { Permission, Role } from '../../utils/types/authentication';
import { hasPermission } from '../../utils/permissions/config';
import AccessDenied from '../../pages/AccessDenied';

interface PermissionGuardProps {
  children: ReactNode;
  permissions: Permission[];
  requireAll?: boolean;
}

function isValidRole(role: string): role is Role {
  return ['owner', 'admin', 'agent', 'viewer'].includes(role);
}

export function PermissionGuard({ 
  children, 
  permissions, 
  requireAll = true 
}: PermissionGuardProps) {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/authentication" replace />;
  }

  if (!isValidRole(user.role)) {
    console.error(`Invalid role: ${user.role}`);
    return <AccessDenied />;
  }

  const hasAccess = requireAll
    ? permissions.every(permission => hasPermission(user.role, permission))
    : permissions.some(permission => hasPermission(user.role, permission));

  if (!hasAccess) {
    return <AccessDenied />;
  }

  return <>{children}</>;
} 