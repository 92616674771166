import React from "react";
import { Card, ListGroup} from "flowbite-react";
import { 
  HiGlobe, 
  HiPlus,
  HiDatabase,
  HiCog,
  HiShieldCheck,

} from "react-icons/hi";
import { motion } from "framer-motion";
import MainLayout from "../../layouts/MainLayout";
import { Link } from "react-router-dom";

const DomainsDocs: React.FC = () => {
  return (
    <MainLayout>
      <motion.div 
        className="px-4 pt-6 max-w mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="mb-4">
          <Link 
            to="/documentation"
            className="text-purple-600 hover:text-purple-700 dark:text-purple-400"
          >
            ← Back to Documentation
          </Link>
        </div>

        <Card className="p-6 shadow-lg dark:bg-gray-800">
          <div className="flex items-center gap-3 mb-6">
            <HiGlobe className="w-8 h-8 text-purple-600 dark:text-purple-400" />
            <h1 className="text-3xl font-bold text-gray-800 dark:text-white">
              Managing Your Domains
            </h1>
          </div>

          <p className="text-gray-600 dark:text-gray-300 mb-8">
            Welcome to the domain management guide! Whether you're setting up your first domain or managing multiple websites, we'll walk you through everything you need to know to get your AI chatbot running smoothly across all your platforms.
          </p>

          <div className="prose dark:prose-invert max-w-none space-y-12">
            <section>
              <h2 className="text-2xl font-semibold mb-6 flex items-center gap-2 text-gray-800 dark:text-white">
                <HiPlus className="w-6 h-6" />
                Setting Up a New Domain
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Let's get your new domain set up! Follow these simple steps to add and configure a new domain for your chatbot.
              </p>
              <div className="space-y-6">
                <ol className="space-y-6">
                  <li className="flex gap-4">
                    <span className="flex-shrink-0 w-8 h-8 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400 font-semibold">
                      1
                    </span>
                    <div>
                      <h3 className="font-medium text-gray-800 dark:text-white mb-2">Navigate to Your Dashboard</h3>
                      <p className="text-gray-600 dark:text-gray-300">
                        Head over to the Domains section in your dashboard and look for the friendly "Add New Domain" button. It's right there waiting for you!
                      </p>
                    </div>
                  </li>
                  <li className="flex gap-4">
                    <span className="flex-shrink-0 w-8 h-8 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400 font-semibold">
                      2
                    </span>
                    <div>
                      <h3 className="font-medium text-gray-800 dark:text-white mb-2">Enter Domain Details</h3>
                      <ListGroup className="mt-2">
                        <ListGroup.Item className="bg-transparent">
                          Website Link (e.g., https://example.com)
                        </ListGroup.Item>
                        <ListGroup.Item className="bg-transparent">
                          Country
                        </ListGroup.Item>
                        <ListGroup.Item className="bg-transparent">
                          Company Name
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </li>
                  <li className="flex gap-4">
                    <span className="flex-shrink-0 w-8 h-8 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400 font-semibold">
                      3
                    </span>
                    <div>
                      <h3 className="font-medium text-gray-800 dark:text-white mb-2">Configure Chatbot Settings</h3>
                      <ListGroup className="mt-2">
                        <ListGroup.Item className="bg-transparent">
                          Chat Bot Name - Choose a name for your AI assistant
                        </ListGroup.Item>
                        <ListGroup.Item className="bg-transparent">
                          Chat Bot Theme - Select a color theme (#HEX code)
                        </ListGroup.Item>
                        <ListGroup.Item className="bg-transparent">
                          Chat Bot Logo - Upload your brand logo
                        </ListGroup.Item>
                        <ListGroup.Item className="bg-transparent">
                          Chat Bot Tagline - Add a welcoming message
                        </ListGroup.Item>
                        <ListGroup.Item className="bg-transparent">
                          Chat Bot Role - Define the AI's purpose
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </li>
                </ol>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-6 flex items-center gap-2 text-gray-800 dark:text-white">
                <HiCog className="w-6 h-6" />
                Making the Most of Your Domain
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Once your domain is set up, there's so much you can do! Here's how to make your chatbot truly shine on your website.
              </p>
              <div className="grid md:grid-cols-2 gap-6">
                <Card className="dark:bg-gray-700">
                  <h3 className="text-lg font-medium mb-4 flex items-center gap-2">
                    <HiDatabase className="w-5 h-5" />
                    Quick Actions
                  </h3>
                  <div className="space-y-3">
                    <p className="text-gray-600 dark:text-gray-300">
                      Here's what you can do with your domain:
                    </p>
                    <ListGroup>
                      <ListGroup.Item className="bg-transparent">
                        Edit domain and chatbot settings
                      </ListGroup.Item>
                      <ListGroup.Item className="bg-transparent">
                        Train your chatbot with custom data
                      </ListGroup.Item>
                      <ListGroup.Item className="bg-transparent">
                        Delete domain when no longer needed
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </Card>

                <Card className="dark:bg-gray-700">
                  <h3 className="text-lg font-medium mb-4 flex items-center gap-2">
                    <HiShieldCheck className="w-5 h-5" />
                    Making Your Chatbot Smarter
                  </h3>
                  <div className="space-y-3">
                    <p className="text-gray-600 dark:text-gray-300">
                      Let's make your chatbot an expert in your field:
                    </p>
                    <ListGroup>
                      <ListGroup.Item className="bg-transparent">
                        Upload training documents (PDF, DOCX, CSV, TXT)
                      </ListGroup.Item>
                      <ListGroup.Item className="bg-transparent">
                        Add custom responses and information
                      </ListGroup.Item>
                      <ListGroup.Item className="bg-transparent">
                        Regular updates to improve accuracy
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </Card>
              </div>
            </section>

            <section className="bg-purple-50 dark:bg-gray-700 rounded-lg p-6">
              <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
                Pro Tips for Success
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-4">
                Here are some tried-and-true tips to make your chatbot experience even better:
              </p>
              <div className="space-y-4">
                <ListGroup>
                  <ListGroup.Item className="bg-transparent">
                    Give your chatbot a friendly, on-brand name that your visitors will love
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent">
                    Match your website's look and feel with complementary colors
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent">
                    Keep your training focused on what matters most to your visitors
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent">
                    Regular updates keep your chatbot fresh and accurate
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </section>
          </div>
        </Card>
      </motion.div>
    </MainLayout>
  );
};

export default DomainsDocs; 