import React, {
  createContext,
  useContext,
  ReactNode,
  useMemo
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkUserSession, AUTH_QUERY_KEY } from "../../api/authentication";
import type { CheckUserSessionResponse, User } from "../types/authentication";
import type { DomainData } from "../types/domains";
import { useQuery } from '@tanstack/react-query';

interface AuthContextType {
  user: User | null;
  authenticated: boolean;
  expired: boolean;
  domains: DomainData[];
  loading?: boolean;
  expiryDate: string | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthGuardProps {
  children: ReactNode;
}

const PUBLIC_ROUTES = [
  '/',
  '/home',
  '/authentication',
  '/hidden-registration',
  '/join-team',
  '/reset'
];

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isPublicRoute = PUBLIC_ROUTES.includes(location.pathname);

  const { data: authData, isLoading } = useQuery({
    queryKey: AUTH_QUERY_KEY,
    queryFn: checkUserSession,
    staleTime: 5 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
    retry: false,
    networkMode: 'always',
    enabled: true,
    select: (response: CheckUserSessionResponse) => {
      if (response.authenticated && isPublicRoute && location.pathname === '/authentication') {
        navigate('/dashboard');
      } else if (!response.authenticated && !isPublicRoute) {
        navigate('/authentication');
      } else if (
        response.authenticated &&
        response.expired &&
        location.pathname !== '/billing'
      ) {
        navigate('/billing');
      }
      return response;
    }
  });

  const contextValue = useMemo<AuthContextType>(() => ({
    user: authData?.user || null,
    authenticated: authData?.authenticated || false,
    expired: authData?.expired || false,
    domains: authData?.domains || [],
    loading: isLoading,
    expiryDate: authData?.expiry_date ?? null,
  }), [
    authData?.user,
    authData?.authenticated,
    authData?.expired,
    authData?.domains,
    authData?.expiry_date,
    isLoading
  ]);

  if (isPublicRoute) {
    return (
      <AuthContext.Provider value={contextValue}>
        {children}
      </AuthContext.Provider>
    );
  }

  if (isLoading) {
    return (
      <AuthContext.Provider value={contextValue}>
        {null /* Or a loading spinner */}
      </AuthContext.Provider>
    );
  }

  if (!authData?.authenticated) {
    navigate('/authentication');
    return null;
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthGuard;
