import React from "react";
import { Card, Alert } from "flowbite-react";
import {
  HiColorSwatch,
  HiChat,
  HiTranslate,
  HiExclamation,
  HiLightBulb,
  HiCog,
} from "react-icons/hi";
import { motion } from "framer-motion";
import MainLayout from "../../layouts/MainLayout";
import { Link } from "react-router-dom";

const CustomizationDocs: React.FC = () => {
  return (
    <MainLayout>
      <motion.div
        className="px-4 pt-6 max-w mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="mb-4">
          <Link
            to="/documentation"
            className="text-purple-600 hover:text-purple-700 dark:text-purple-400"
          >
            ← Back to Documentation
          </Link>
        </div>

        <Card className="p-6 shadow-lg dark:bg-gray-800">
          <div className="flex items-center gap-3 mb-6">
            <HiColorSwatch className="w-8 h-8 text-purple-600 dark:text-purple-400" />
            <h1 className="text-3xl font-bold text-gray-800 dark:text-white">
              Preferences
            </h1>
          </div>

          <p className="text-gray-600 dark:text-gray-300 mb-8">
            Configure your notification preferences, call settings, and language
            options to customize your Rupeni experience.
          </p>

          <div className="prose dark:prose-invert max-w-none space-y-12">
            <section>
              <h2 className="text-2xl font-semibold mb-6 flex items-center gap-2">
                <HiChat className="w-6 h-6" />
                Notification Settings
              </h2>
              <Card className="dark:bg-gray-700">
                <div className="space-y-6">
                  <div>
                    <h3 className="font-medium text-gray-800 dark:text-white mb-2">
                      Desktop Notifications
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      Stay informed with desktop alerts for:
                    </p>
                    <ul className="list-disc pl-6 mt-2 text-gray-600 dark:text-gray-300">
                      <li>Incoming calls</li>
                      <li>New chat messages</li>
                      <li>System updates</li>
                    </ul>
                    <div className="mt-4">
                      <Alert color="info" icon={HiLightBulb}>
                        Pro tip: Use the "Send Test Notification" button to make
                        sure your notifications are working correctly!
                      </Alert>
                    </div>
                  </div>

                  <div>
                    <h3 className="font-medium text-gray-800 dark:text-white mb-2">
                      Email Notifications
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      Choose to receive email updates for:
                    </p>
                    <ul className="list-disc pl-6 mt-2 text-gray-600 dark:text-gray-300">
                      <li>Weekly activity reports</li>
                      <li>System updates and changes</li>
                      <li>Important announcements</li>
                    </ul>
                  </div>
                </div>
              </Card>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-6 flex items-center gap-2">
                <HiCog className="w-6 h-6" />
                Call Settings
              </h2>
              <Card className="dark:bg-gray-700">
                <div className="space-y-6">
                  <div>
                    <h3 className="font-medium text-gray-800 dark:text-white mb-2">
                      Call Activation on Login
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      Manage when your call features are active:
                    </p>
                    <ul className="list-disc pl-6 mt-2 text-gray-600 dark:text-gray-300">
                      <li>Automatically activate calls when you log in</li>
                      <li>Calls disable when you're not logged in</li>
                      <li>Perfect for managing your availability</li>
                    </ul>
                  </div>

                  <div>
                    <h3 className="font-medium text-gray-800 dark:text-white mb-2">
                      Call Recording
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      Configure your call recording preferences:
                    </p>
                    <ul className="list-disc pl-6 mt-2 text-gray-600 dark:text-gray-300">
                      <li>Enable/disable automatic call recording</li>
                      <li>Automatic deletion after 30 days</li>
                      <li>Secure storage of all recordings</li>
                    </ul>
                  </div>
                </div>
              </Card>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-6 flex items-center gap-2">
                <HiTranslate className="w-6 h-6" />
                Language Preferences
              </h2>
              <Card className="dark:bg-gray-700">
                <div className="space-y-4">
                  <h3 className="font-medium text-gray-800 dark:text-white mb-2">
                    Default Language
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300">
                    Choose your preferred language for:
                  </p>
                  <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
                    <li>Dashboard interface</li>
                    <li>System messages</li>
                    <li>Notifications</li>
                  </ul>
                  <div className="mt-4">
                    <p className="text-gray-600 dark:text-gray-300">
                      Currently supported languages:
                    </p>
                    <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
                      <li>English</li>
                      <li>Español</li>
                      <li>Français</li>
                    </ul>
                  </div>
                </div>
              </Card>
            </section>

            <Alert color="info" icon={HiExclamation}>
              Remember to click "Save Changes" after updating your preferences
              to apply your new settings!
            </Alert>
          </div>
        </Card>
      </motion.div>
    </MainLayout>
  );
};

export default CustomizationDocs;
