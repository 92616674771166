import React from "react";
import { UserAvatar } from "./UserAvatar";
import { formatTime } from "../utils/formatters";
import { extractNameAndEmail, getLastMessage } from "../utils/messageHelpers";
import { ChatLogItemProps } from "../types";

export const ChatLogItem: React.FC<ChatLogItemProps> = ({
  log,
  isSelected,
  isHighlighted,
  onClick,
  activeCallSessions,
  assignedChats,
}: Readonly<ChatLogItemProps>) => {
  const { name } = extractNameAndEmail(log.message);
  const hasActiveCall = activeCallSessions.has(log.session_id);
  const isAssigned = assignedChats.has(log.session_id);

  return (
    <button
      type="button"
      className={`w-full text-left flex flex-col p-4 border-b transition-colors overflow-hidden duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 
        ${isSelected ? "bg-blue-50 dark:bg-blue-900" : ""}
        ${
          isHighlighted
            ? "bg-yellow-100 dark:bg-yellow-900 border-l-4 border-yellow-500"
            : isAssigned
            ? "bg-green-100 dark:bg-green-900 border-l-4 border-green-500"
            : "hover:bg-gray-100 dark:hover:bg-gray-700"
        }
      `}
      onClick={onClick}
      aria-pressed={isSelected}
      aria-label={`Chat with ${name}`}
    >
      <div className="flex items-center w-full min-w-0">
        <div className="flex-shrink-0">
          <UserAvatar name={name} />
        </div>
        <div className="ml-4 flex-1 min-w-0 flex justify-between items-center">
          <span className="font-medium text-gray-900 dark:text-gray-100 truncate">
            {name}
          </span>
          <span className="text-xs text-gray-500 dark:text-gray-400 flex-shrink-0 ml-2">
            {formatTime(new Date(log.created_at))}
          </span>
        </div>
      </div>
      <div className="mt-2 text-sm text-gray-500 dark:text-gray-400 truncate">
        {getLastMessage(log.message)}
      </div>
      {(isHighlighted || isAssigned) && (
        <div className="mt-2 flex justify-between items-center">
          <span className={`text-sm font-semibold truncate ${
            isHighlighted 
              ? "text-yellow-600 dark:text-yellow-400"
              : "text-green-600 dark:text-green-400"
          }`}>
            {isHighlighted 
              ? (hasActiveCall ? "Incoming call" : "Human assistance requested")
              : "Chat in progress"}
          </span>
        </div>
      )}
    </button>
  );
}
