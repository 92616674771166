import React, { useRef } from 'react';
import { FileInput, Label } from 'flowbite-react';

interface CustomFileInputProps {
  id: string;
  accept: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileName: string;
}

const CustomFileInput: React.FC<CustomFileInputProps> = ({ id, accept, onChange, fileName }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="flex flex-col space-y-1">
      <Label htmlFor={id} className="sr-only">
        Upload file
      </Label>
      <div className="flex items-center w-full ">
        <button
          type="button"
          onClick={handleClick}
          className="px-4 py-[10px] w-32  text-sm font-medium text-white bg-gray-800 border border-gray-200 rounded-tl-lg rounded-bl-lg hover:bg-gray-700  focus:z-10  "
        >
          Choose File
        </button>
        <span className="text-sm px-4 w-full py-[10px] text-gray-900 dark:text-gray-400 border border-gray-200 bg-[#F8FAFB] rounded-tr-lg rounded-br-lg">
          {fileName || 'No file chosen'}
        </span>
      </div>
      <FileInput
        id={id}
        accept={accept}
        onChange={onChange}
        className="hidden"
        ref={fileInputRef}
      />
    </div>
  );
};

export default CustomFileInput;