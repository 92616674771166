import React, { useState } from "react";
import { HiLockClosed } from "react-icons/hi";
import { Card, Button, Spinner, TextInput, Label } from "flowbite-react";
import MainLayout from "../../layouts/MainLayout";
import { useAuth } from "../../utils/helpers/authWrapper";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateUserPassword } from "../../api/settings";
import { Breadcrumb } from "../../components/Breadcrumb";

interface ChangePasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePassword: React.FC = () => {
  const { user } = useAuth();
  const [form, setForm] = useState<ChangePasswordForm>({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!user) {
      toast.error("User not authenticated");
      return;
    }
    if (form.newPassword !== form.confirmPassword) {
      toast.error("New password and confirm password do not match");
      return;
    }
    setIsSubmitting(true);
    try {
      const result = await updateUserPassword(form.currentPassword, form.newPassword, user.id);
      if (result === "Success") {
        toast.success("Password updated successfully");
        setForm({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        toast.error("Failed to update password");
      }
    } catch (error) {
      console.error("Error updating password:", error);
      if (error instanceof Error) {
        if (error.message === "Invalid current password") {
          toast.error("Invalid current password");
        } else {
          toast.error(error.message);
        }
      } else {
        toast.error("An error occurred while updating the password");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <MainLayout>
      <div className="px-4 py-8">
        <Breadcrumb 
          items={[
            { label: 'Change Password', path: '/settings/change-password' }
          ]} 
        />
        <Card>
          <div className="flex items-center gap-4 mb-6">
            <HiLockClosed className="w-8 h-8 text-gray-800 dark:text-gray-400" />
            <h1 className="text-2xl font-bold dark:text-white">
              Change Password
            </h1>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            {[
              { label: "Current Password", name: "currentPassword" },
              { label: "New Password", name: "newPassword" },
              { label: "Confirm New Password", name: "confirmPassword" },
            ].map((field) => (
              <div key={field.name}>
                <Label htmlFor={field.name} className="mb-2 block">
                  {field.label}
                </Label>

                <TextInput
                  id={field.name}
                  name={field.name}
                  type="password"
                  value={form[field.name as keyof ChangePasswordForm]}
                  onChange={handleInputChange}
                  className="w-[80%]"
                  autoComplete="off"
                  required
                />
              </div>
            ))}
            <div className="mt-6">
              <Button
                type="submit"
                disabled={isSubmitting}
                className="rounded-md bg-[#006AC1] hover:bg-[#006AC1] text-white font-medium"
              >
                {isSubmitting ? <Spinner size="sm" /> : "Save Changes"}
              </Button>
            </div>
          </form>
        </Card>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ zIndex: 9999999999999 }}
      />
    </MainLayout>
  );
};

export default ChangePassword;
