import React, { useState, useEffect } from "react";
import { useAuth } from "../../utils/helpers/authWrapper";
import MainLayout from "../../layouts/MainLayout";
import { Card, TextInput, Button, Spinner, Label } from "flowbite-react";
import { HiOfficeBuilding } from "react-icons/hi";
import { getCompanyDetails, updateCompanyDetails } from "../../api/settings";
import { CompanyDetails as CompanyDetailsType } from "../../utils/types/settings";
import { z } from "zod";
import { toast, ToastContainer } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumb } from '../../components/Breadcrumb';

const phoneRegex = /^\+?[0-9\s-()]+$/;

const companyDetailsSchema = z.object({
  company_name: z.string().nullable(),
  name: z.string().min(1, "Full name is required"),
  title: z.string().nullable(),
  email: z.string().email("Invalid email address"),
  phone: z.string().regex(phoneRegex, "Invalid phone number format").nullable(),
  address: z.string().nullable(),
  finance_email: z.string().email("Invalid email address").nullable(),
  id: z.number(),
});

const CompanyDetails: React.FC = () => {
  const { user, loading } = useAuth();
  const [form, setForm] = useState<CompanyDetailsType>({
    company_name: "",
    name: "",
    title: "",
    email: "",
    phone: "",
    address: "",
    finance_email: "",
    id: 0,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState<Partial<Record<keyof CompanyDetailsType, string>>>({});

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      if (user?.id) {
        try {
          const details = await getCompanyDetails(user.id);
          setForm(details);
        } catch (error) {
          console.error("Error fetching company details:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchCompanyDetails();
  }, [user]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
    // Clear the error for this field when it's changed
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});

    try {
      const validatedForm = companyDetailsSchema.parse(form);
      await updateCompanyDetails(validatedForm);
      toast.success('Company details updated successfully!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      if (error instanceof z.ZodError) {
        const fieldErrors: Partial<Record<keyof CompanyDetailsType, string>> = {};
        error.errors.forEach((err) => {
          if (err.path) {
            fieldErrors[err.path[0] as keyof CompanyDetailsType] = err.message;
          }
        });
        setErrors(fieldErrors);
        toast.error('Please correct the errors in the form.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        console.error("Error updating company details:", error);
        toast.error('An error occurred while updating company details.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading || isLoading) {
    return (
      <MainLayout>
        <div className="flex justify-center items-center h-screen">
          <Spinner size="xl" />
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="px-4 py-8">
        <Breadcrumb 
          items={[
            { label: 'Company Details', path: '/settings/company-details' }
          ]} 
        />
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <Card>
              <div className="flex items-center gap-4 mb-6">
                <HiOfficeBuilding className="w-8 h-8 text-gray-800 dark:text-gray-400" />
                <h1 className="text-xl md:text-2xl font-bold dark:text-white">Company Details</h1>
              </div>
              <form onSubmit={handleSubmit} className="space-y-4 md:space-y-3">
                {[
                  { label: "Company Name", name: "company_name" },
                  { label: "Full Name of Primary Contact", name: "name", required: true },
                  { label: "Title", name: "title" },
                  { label: "Email", name: "email", type: "email", required: true },
                  { label: "Phone", name: "phone", type: "tel", placeholder: "+1 1234567890" },
                  { label: "Address", name: "address" },
                  { label: "Finance/Billing Email", name: "finance_email", type: "email" },
                ].map((field) => (
                  <div key={field.name}>
                    <Label htmlFor={field.name} className="mb-2 block text-sm md:text-base">
                      {field.label}
                      {field.required && <span className="text-red-500 ml-1">*</span>}
                    </Label>
                    <TextInput
                      id={field.name}
                      name={field.name}
                      type={field.type ?? "text"}
                      value={form[field.name as keyof CompanyDetailsType] ?? ""}
                      onChange={handleInputChange}
                      className="w-full md:w-[80%]"
                      autoComplete="off"
                      required={field.required}
                      placeholder={field.placeholder}
                    />
                    {errors[field.name as keyof CompanyDetailsType] && (
                      <p className="mt-1 text-sm text-red-500">
                        {errors[field.name as keyof CompanyDetailsType]}
                      </p>
                    )}
                  </div>
                ))}
              </form>
            </Card>
            <div className="mt-6">
              <Button 
                type="submit" 
                disabled={isSubmitting} 
                onClick={handleSubmit} 
                className="w-full md:w-auto rounded-md bg-[#006AC1] hover:bg-[#006AC1]"
              >
                {isSubmitting ? <Spinner size="sm" /> : "Save Changes"}
              </Button>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ zIndex: 9999999999999 }}
        
      />
    </MainLayout>
  );
};

export default CompanyDetails;
