import { Permission, Role } from '../types/authentication';

export const ROLE_PERMISSIONS: Record<Role, Permission[]> = {
  owner: [
    'view_dashboard',
    'manage_domains',
    'create_domain',
    'view_domain',
    'manage_training',
    'manage_billing',
    'manage_settings',
    'manage_roles',
    'view_chats',
    'manage_chats',
    'chat_takeover',
    'view_analytics',
    'manage_company_details',
    'manage_chat_widget',
    'manage_preferences',
    'change_password'
  ],
  admin: [
    'view_dashboard',
    'manage_domains',
    'create_domain',
    'view_domain', 
    'manage_training',
    'manage_billing',
    'manage_settings',
    'manage_roles',
    'view_chats',
    'manage_chats',
    'chat_takeover',
    'view_analytics',
    'manage_company_details',
    'manage_chat_widget',
    'manage_preferences',
    'change_password'
  ],
  agent: [
    'view_dashboard',
    'view_chats',
    'manage_chats',
    'chat_takeover',
    'view_analytics',
    'manage_preferences',
    'change_password'
  ],
  viewer: [
    'view_dashboard',
    'view_chats',
    'view_analytics',
    'change_password'
  ]
};

export function hasPermission(role: Role, permission: Permission): boolean {
  return ROLE_PERMISSIONS[role]?.includes(permission) ?? false;
}

export function hasAnyPermission(role: Role, permissions: Permission[]): boolean {
  return permissions.some(permission => hasPermission(role, permission));
}

export function hasAllPermissions(role: Role, permissions: Permission[]): boolean {
  return permissions.every(permission => hasPermission(role, permission));
} 