import React from 'react';

interface CustomToggleProps {
  id: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
}

const CustomToggle: React.FC<CustomToggleProps> = ({ 
  id, 
  label, 
  checked, 
  disabled = false,
  onChange 
}) => (
  <label htmlFor={id} className={`flex items-center relative w-max select-none ${disabled ? 'cursor-not-allowed opacity-60' : 'cursor-pointer'}`}>
    <span className="text-lg font-bold mr-3">{label}</span>
    <input
      type="checkbox"
      id={id}
      checked={checked}
      disabled={disabled}
      onChange={(e) => onChange(e.target.checked)}
      className="peer checked:bg-none appearance-none border-2 border-gray-300 transition-colors w-16 h-8 rounded-full focus:outline-none focus:border-none focus:ring-[0px] bg-transparent checked:bg-[#00B94D] dark:checked:bg-[#00B94D] disabled:cursor-not-allowed"
    />
    <span className={`absolute font-medium text-xs uppercase right-1 dark:text-white text-black ${checked ? 'opacity-0' : 'opacity-100'}`}> OFF </span>
    <span className={`absolute font-medium text-xs uppercase right-10 text-white ${checked ? 'opacity-100' : 'opacity-0'}`}>ON</span>
    <span className={`w-6 h-6 right-9 absolute rounded-full transform transition-transform peer-checked:translate-x-8 ${checked ? 'bg-gray-200' : 'bg-gray-500'}`} />
  </label>
);

export default CustomToggle;
