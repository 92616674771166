import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Label, Spinner } from "flowbite-react";
import { ReactTyped } from "react-typed";
import { rupeni } from "../../assets/images";
import { updatePassword } from "../../api/authentication";
import { motion } from "framer-motion";

const ResetPW: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const validatePasswords = () => {
    const newErrors: { [key: string]: string } = {};
    if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (newPassword.length < 8) {
      newErrors.newPassword = "Password must be at least 8 characters long";
    }
    return newErrors;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newErrors = validatePasswords();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true);
      try {
        const response = await updatePassword(newPassword, token ?? "");
        if (response === "Password updated!") {
          setNewPassword("");
          setConfirmPassword("");
          setErrors({});
          setSuccess(response);
        } else {
          setErrors({
            apiError: response || "An error occurred. Please try again later.",
          });
        }
      } catch (error) {
        setErrors({ apiError: "An error occurred. Please try again later." });
      } finally {
        setLoading(false);
      }
    }
  };

  const formVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  };

  const childVariants = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-100 to-gray-300 flex justify-center items-center">
      <div className="flex flex-col lg:flex-row bg-white rounded-lg shadow-lg overflow-hidden max-w-4xl w-full">
        <motion.div 
          className="hidden lg:flex lg:w-1/2 bg-gradient-to-r from-[#0D151F] to-[#1E212A] text-white flex-col items-center justify-center p-8"
          variants={childVariants}
          initial="initial"
          animate="animate"
        >
          <img
            src={rupeni}
            alt="Logo"
            className="mt-8 w-44 h-44 rounded-full border-4 border-white"
          />
          <div className="flex flex-col items-center justify-center mt-8">
            <h1 className="text-4xl font-bold mb-4">
              <ReactTyped
                strings={["Welcome to Rupeni", "AI Made easy", "Discover the potential of AI with Rupeni"]}
                typeSpeed={100}
                backSpeed={50}
                loop
              />
            </h1>
            <p className="text-lg text-center">
              You are one step away from resetting your password. Enter your new
              password below to proceed.
            </p>
          </div>
        </motion.div>
        <div className="w-full lg:w-1/2 p-8 flex items-center justify-center">
          <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-lg relative z-10">
            <motion.h2 
              className="text-3xl font-bold text-center mb-8 text-gray-800"
              variants={childVariants}
              initial="initial"
              animate="animate"
            >
              Reset Your Password
            </motion.h2>
            {success ? (
              <motion.div 
                variants={childVariants}
                initial="initial"
                animate="animate"
                className="flex flex-col items-center justify-center text-center"
              >
                <p className="text-green-500 mb-4">{success}</p>
                <button
                  className="w-48 bg-bg border border-bg hover:border-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-bg transition duration-300 transform hover:scale-105"
                  onClick={() => (window.location.href = "/authentication")}
                >
                  <span className="text-sm p-8">Login Now</span>
                </button>
              </motion.div>
            ) : (
              <motion.form 
                onSubmit={handleSubmit}
                variants={formVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.3 }}
                className="space-y-4"
              >
                <motion.div variants={childVariants}>
                  <div>
                    <Label
                      htmlFor="new-password"
                      value="New Password"
                      className="block text-sm font-medium text-gray-700"
                    />
                    <div className="relative">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none text-gray-400">
                        <i className="fas fa-lock"></i>
                      </div>
                      <input
                        id="new-password"
                        type="password"
                        placeholder="Enter new password"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        aria-required="true"
                        aria-describedby="new-password-help"
                      />
                    </div>
                    {errors.newPassword && (
                      <p id="new-password-help" className="text-red-500 text-xs mt-1">
                        {errors.newPassword}
                      </p>
                    )}
                  </div>
                  <div className="mt-4">
                    <Label
                      htmlFor="confirm-password"
                      value="Confirm Password"
                      className="block text-sm font-medium text-gray-700"
                    />
                    <div className="relative">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none text-gray-400">
                        <i className="fas fa-lock"></i>
                      </div>
                      <input
                        id="confirm-password"
                        type="password"
                        placeholder="Confirm new password"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        aria-required="true"
                        aria-describedby="confirm-password-help"
                      />
                    </div>
                    {errors.confirmPassword && (
                      <p id="confirm-password-help" className="text-red-500 text-xs mt-1">
                        {errors.confirmPassword}
                      </p>
                    )}
                  </div>
                  <div className="flex justify-center mt-6">
                    <button
                      className="w-48 bg-bg border border-bg hover:border-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-bg transition duration-300 transform hover:scale-105"
                      type={loading ? "button" : "submit"}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner aria-label="Spinner" size="sm" />
                      ) : (
                        <span className="text-sm p-8">Reset Password</span>
                      )}
                    </button>
                  </div>
                  {errors.apiError && (
                    <p className="text-red-500 text-xs mt-1 text-center">
                      {errors.apiError}
                    </p>
                  )}
                </motion.div>
              </motion.form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPW;
