import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import { initGA, logPageView } from "./utils/googleanalytics";
import AuthGuard, { useAuth } from "./utils/helpers/authWrapper";
import { WebSocketProvider } from './utils/context';
import GlobalAlert from "./components/Alert";
// import { CallAlert } from "./components/Alert/call_alert";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PermissionGuard } from './components/guards/PermissionGuard';

// Import all your page components
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import UserSetup from "./pages/Login/user";
import ResetPW from "./pages/ResetPW";
import Dashboard from "./pages/Dashboard";
import Domain from "./pages/Domain";
import AddDomain from "./pages/Domain/add";
import ViewEditDomain from "./pages/Domain/view";
import Chats from "./pages/Chats";
import Train from "./pages/Domain/train";
import Billing from "./pages/Billing";
import Settings from "./pages/Settings";
import CompanyDetails from "./pages/Settings/CompanyDetails";
import ChatWidget from "./pages/Settings/ChatWidget";
import Preference from "./pages/Settings/Preference";
import RolePermissions from "./pages/Settings/RolePermissions";
import ChangePassword from "./pages/Settings/ChangePassword";
import NotFound from "./pages/NotFound";
import Documentation from "./pages/Documentation";
import InstallationDocs from "./pages/Documentation/Installation";
import ChatWidgetDocs from "./pages/Documentation/ChatWidget";
import TrainingDocs from "./pages/Documentation/Training";
import DomainsDocs from "./pages/Documentation/Domains";
import RolesDocs from "./pages/Documentation/Roles";
import CustomizationDocs from "./pages/Documentation/Customization";

const AppRoutes: React.FC = () => {
  const location = useLocation();
  const { authenticated } = useAuth();

  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <Routes location={location}>
      <Route path="/" element={<Landing />} />
      <Route path="/home" element={<Landing />} />
      <Route path="/authentication" element={<Login />} />
      <Route path="/hidden-registration" element={<Login />} />
      <Route path="/join-team" element={<UserSetup />} />
      <Route path="/reset" element={<ResetPW />} />
      {authenticated && (
        <>
          <Route path="/dashboard" element={
            <PermissionGuard permissions={['view_dashboard']}>
              <Dashboard />
            </PermissionGuard>
          } />
          <Route path="/domains" element={
            <PermissionGuard permissions={['manage_domains']}>
              <Domain />
            </PermissionGuard>
          } />
          <Route path="/domains/add" element={
            <PermissionGuard permissions={['create_domain']}>
              <AddDomain />
            </PermissionGuard>
          } />
          <Route path="/domains/view" element={
            <PermissionGuard permissions={['view_domain']}>
              <ViewEditDomain />
            </PermissionGuard>
          } />
          <Route path="/chats" element={
            <PermissionGuard permissions={['view_chats']}>
              <Chats />
            </PermissionGuard>
          } />
          <Route path="/domains/chatbot-training" element={
            <PermissionGuard permissions={['manage_training']}>
              <Train />
            </PermissionGuard>
          } />
          <Route path="/billing" element={
            <PermissionGuard permissions={['manage_billing']}>
              <Billing />
            </PermissionGuard>
          } />
          <Route path="/settings" element={
            <PermissionGuard permissions={['change_password']}>
              <Settings />
            </PermissionGuard>
          } />
          <Route path="/settings/company-details" element={
            <PermissionGuard permissions={['manage_company_details']}>
              <CompanyDetails />
            </PermissionGuard>
          } />
          <Route path="/settings/chat-widget" element={
            <PermissionGuard permissions={['manage_chat_widget']}>
              <ChatWidget />
            </PermissionGuard>
          } />
          <Route path="/settings/preference" element={
            <PermissionGuard permissions={['manage_preferences']}>
              <Preference />
            </PermissionGuard>
          } />
          <Route path="/settings/role-permission" element={
            <PermissionGuard permissions={['manage_roles']}>
              <RolePermissions />
            </PermissionGuard>
          } />
          <Route path="/settings/change-password" element={
            <PermissionGuard permissions={['change_password']}>
              <ChangePassword />
            </PermissionGuard>
          } />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/documentation/installation" element={<InstallationDocs />} />
          <Route path="/documentation/chat-widget" element={<ChatWidgetDocs />} />
          <Route path="/documentation/training" element={<TrainingDocs />} />
          <Route path="/documentation/domains" element={<DomainsDocs />} />
          <Route path="/documentation/roles" element={<RolesDocs />} />
          <Route path="/documentation/preferences" element={<CustomizationDocs />} />
        </>
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthGuard>
          <WebSocketProvider>
            <AppRoutes />
            <GlobalAlert />
            {/* <CallAlert /> */}
          </WebSocketProvider>
        </AuthGuard>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
