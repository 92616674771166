import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/MainLayout";
import { Card } from "flowbite-react";
import DomainsTable from "./components/table";
import { useAuth } from "../../utils/helpers/authWrapper";
import Spinner from "../../components/Spinner";
import { DomainData } from "../../utils/types/domains";
import { motion } from "framer-motion";

const Domain: React.FC = () => {
  const { domains: initialDomains, loading, user } = useAuth();
  const [domains, setDomains] = useState<DomainData[]>([]);

  useEffect(() => {
    if (initialDomains && initialDomains.length > 0) {
      setDomains(initialDomains);
    }
  }, [initialDomains]);

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  return (
    <MainLayout>
      <motion.div
        className="px-4 pt-6"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={{ duration: 0.5 }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Card className="p-6 shadow-lg">
            <h1 className="text-3xl font-bold  text-gray-800 dark:text-gray-200">
              Manage Your Domains
            </h1>
            <p className="text-gray-600 dark:text-gray-400">
              Here you can view, add, edit, and manage all your registered domains and their associated chatbots.
            </p>
            {user && (
              <DomainsTable domains={domains} setDomains={setDomains} user={user} />
            )}
          </Card>
        )}
      </motion.div>
    </MainLayout>
  );
};

export default Domain;
